import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ICondition, multiFilterArray } from 'utils/array'
import { arraySort } from 'utils/arraySort'
import { isMatched } from 'utils/validator'
import { ITransactionData } from '../../../views/dashboard/components/blocks/Transactions/ITransactions'
import Icon from '../Icon'
import TransactionOrder from '../TransactionOrder'
import * as S from './styles'

type Props = {
  data?: ITransactionData[]
  remove?: (id: string) => void
  filters?: {
    hiddenPairs: boolean
    onlyBuy: boolean
    onlySell: boolean
    status: string
  }
}

const cols = [
  {
    title: 'Date',
    prop: 'date',
  },
  {
    title: 'Pair',
    prop: 'pair',
  },
  {
    title: 'Side',
    prop: 'side',
  },
  {
    title: 'Price',
    prop: 'price',
  },
  {
    title: 'Fee',
    prop: 'fee',
  },
  {
    title: 'Total',
    prop: 'total',
  },
]

const TransactionTable = ({ data, remove, filters }: Props) => {
  const {
    executedOrders = [],
    account,
    poolsList,
    currentHPoolTokenContract,
  } = useSelector((state: any) => ({
    poolsList: state.tradingPair.hPoolTokensList || [],
    currentHPoolTokenContract: state.contracts.currentHPoolTokenContract?.address,
    executedOrders: state.orderbook.executedOrders,
    account: state.wallet.account,
  }))
  const [sortProp, setSortProp] = useState(cols?.[0].prop)
  const [isAsc, setIsAsc] = useState(false)

  const targetedOrder = clsx({ sell: filters.onlySell, buy: filters.onlyBuy })

  const onlyUsersTxs = (tx) => isMatched(tx.maker, account) || isMatched(tx.taker, account)

  const getPoolContractAddress = (pool) => pool?.contract_address

  const findPoolByAddress = (address: string) =>
    poolsList.find((pool: any) => isMatched(getPoolContractAddress(pool), address))

  const invertOrderType = (orderType) => (orderType === 'buy' ? 'sell' : 'buy')

  const processExecutedOrders = (order) => {
    const { transaction_hash: txHash, coinAddress, taker, side, id } = order ?? {}

    const matchedPool = findPoolByAddress(coinAddress) ?? {}

    const amTheTaker = isMatched(account, taker)

    const correctOrderType = amTheTaker ? side : invertOrderType(side)

    const { symbol: coin, hpool_token_icon: coinImage } = matchedPool
    return { ...order, coinImage, coin, txHash, completed: true, side: correctOrderType }
  }

  const processedExecutedOrders = useMemo(
    () => executedOrders?.filter(onlyUsersTxs)?.map(processExecutedOrders),
    [executedOrders.length, account, poolsList?.length],
  )

  const filterConditions: ICondition[] = [
    {
      filterAt: filters.onlyBuy && !filters.onlySell,
      filterFunc: (item) => item.side === 'buy',
    },
    {
      filterAt: filters.onlySell && !filters.onlyBuy,
      filterFunc: (item) => item.side === 'sell',
    },
    {
      filterAt: filters.hiddenPairs,
      filterFunc: (item) => isMatched(item.coinAddress, currentHPoolTokenContract),
    },
  ]

  const filteredOrders = useMemo(
    () => multiFilterArray(processedExecutedOrders, filterConditions),
    [filters, processedExecutedOrders.length, currentHPoolTokenContract],
  )

  const sortedDataSrc = useMemo(
    () =>
      arraySort(filteredOrders, sortProp, {
        isAsc,
        isString: typeof filteredOrders?.[0]?.[sortProp] === 'string',
      }),
    [filteredOrders, sortProp, isAsc],
  )

  const handleColClick = (col) => {
    setSortProp(col.prop)

    if (sortProp === col.prop) {
      setIsAsc(!isAsc)
    } else setIsAsc(true)
  }
  const showTable = executedOrders?.length && poolsList?.length

  return showTable ? (
    <S.Wrapper>
      <S.Table>
        <S.Thead>
          <S.Tr>
            {cols.map((col) => (
              <S.Th key={col.prop} onClick={() => handleColClick(col)}>
                <S.Title>{col.title}</S.Title>
                <S.IconWrapper>
                  <Icon name="ArrowTop" style={{ transform: col.prop === sortProp && isAsc && 'rotate(180deg)' }} />
                </S.IconWrapper>
              </S.Th>
            ))}
            <S.Th />
          </S.Tr>
        </S.Thead>
        <S.Tbody>
          {sortedDataSrc.length > 0 &&
            sortedDataSrc.map((item) => <TransactionOrder key={item?.ts} data={item} remove={() => remove(item.id)} />)}
          <S.Tr />
        </S.Tbody>
      </S.Table>
    </S.Wrapper>
  ) : (
    <S.EmptyDataWrapper>You have no trade history yet.</S.EmptyDataWrapper>
  )
}

export default TransactionTable
