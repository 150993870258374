import React, { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SET_PENDING_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import { mapToFitDapiModel, setupWeb3EventListener } from 'utils/tradeOrdersService'
import { OrdersEventsListenersContext } from '.'

export default function LogKill() {
  const dispatch = useDispatch()
  const { pendingOrders, account, matchingMarketContractAddress } = useContext(OrdersEventsListenersContext)

  useEffect(() => {
    const subscribtionObj: any = setupWeb3EventListener(
      'LogKill',
      async (evt: any) => {
        console.log('LogKill', evt)
        const mappedToFitDapiModel = mapToFitDapiModel(evt)
        console.log('LogKill 2')

        const deletedOrderId = parseInt(mappedToFitDapiModel.id as string, 16)
        console.log('LogKill 3')

        const pendingOrdersAfterCancel = pendingOrders.filter((order) => +order.id !== +deletedOrderId)
        console.log('LogKill 4')

        dispatch({ type: SET_PENDING_ORDERS, payload: pendingOrdersAfterCancel })
      },
      () => {},
    )
    return () => {
      subscribtionObj.then((subscribtion) => {
        subscribtion.unsubscribe((err, success) => {
          console.log('removing subscribtion ' + subscribtion.id)
        })
      })
    }
  }, [pendingOrders.length, matchingMarketContractAddress, account])

  return <></>
}
