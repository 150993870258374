import { useEffect, useState, useRef } from 'react'
import { useOnClickOutside } from 'hooks/useClickAway'
import Icon from '../Icon'
import * as S from './styles'

// type DropdownItem = {
//  title: string
//  link?: string
//  action?:()=> void
// }
export type DropdownProps = {
  title: string | number
  children?: JSX.Element
  active?: boolean
  setDropdownState?: (newDropdownState: boolean) => void
  action?: (name: void) => void
}

const Dropdown = ({ title, children, active, setDropdownState }: DropdownProps) => {
  const [state, setState] = useState(active)
  const ref = useRef(null)

  useEffect(() => {
    setState(active)
  }, [active])

  const handleClose = () => {
    setDropdownState(false)
  }

  useOnClickOutside(ref, handleClose)

  return (
    <S.Wrapper ref={ref}>
      <S.Header onClick={() => setDropdownState(!active)}>
        <S.Title>{title}</S.Title>
        <S.WrapperImage>
          <Icon name="ArrowTop" onClick={() => setState(!active)} style={{ transform: active && 'rotate(180deg)' }} />
        </S.WrapperImage>
      </S.Header>
      {state && (
        <S.WrapperContent className="animation">
          <S.ContainerContent>{children}</S.ContainerContent>
        </S.WrapperContent>
      )}
    </S.Wrapper>
  )
}

export default Dropdown
