import { useCallback } from 'react'
import { connect, useSelector } from 'react-redux'
import { handleInputChange } from 'store/actions/inputActions'
import { IOrderBookData } from '../../../views/dashboard/components/blocks/Graph/IGraph'
import Icon from '../Icon'

import * as S from './styles'

export type Props = {
  data: IOrderBookData
  handleInputChange: (inputName: string, value: number | string) => void
}
const OrderBookOrder = ({ data, handleInputChange }: Props) => {
  const { executedOrdersSrc } = useSelector((state: any) => ({
    executedOrdersSrc: state.orderbook.executedOrdersSrc,
  }))
  const handleClick = useCallback(() => {
    handleInputChange('amount', data.total)
    handleInputChange('price', data.price)
    handleInputChange('focusedOrderType', data.side)

    // if (data.side === 'buy') {
    //   handleInputChange('sellAmount', data.total)
    //   handleInputChange('sellPrice', data.price)
    // } else {
    //   handleInputChange('buyAmount', data.total)
    //   handleInputChange('buyPrice', data.price)
    // }
  }, [handleInputChange, data.amount, data.price, data.side, executedOrdersSrc])

  return (
    <S.Tr onClick={handleClick}>
      <S.Td>${data.price}</S.Td>
      <S.Td>
        <S.ContainerFlex>
          <S.Span data={data}>
            {data.total} {data.symbol}
          </S.Span>
          <S.IconWrapper>
            <Icon
              name={data.side === 'buy' ? 'ArrowGreenLeft' : 'ArrowRedRight'}
              style={{ transform: data.side === 'sell' ? 'rotate(180deg)' : null }}
            />
            <Icon name="ArrowLeftWhite" style={{ transform: 'rotate(180deg)' }} />
          </S.IconWrapper>
          <S.BusdWrapper>{data.price * data.total} BUSD</S.BusdWrapper>
        </S.ContainerFlex>
      </S.Td>
    </S.Tr>
  )
}

export default connect(null, {
  handleInputChange,
})(OrderBookOrder)
