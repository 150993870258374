import * as orderbookActionTypes from '../actionTypes/orderbookActionTypes'

const initialState = {
  tradingPair: 'DAI/HPool',
  buyOrders: [],
  sellOrders: [],
  tradeHistory: false,
  chartStats: [],
  availableOpenOrders: [],
  pendingOrders: [],
  executedOrders: [],
  executedOrdersSrc: {},
}
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case orderbookActionTypes.SET_AVAILABLE_OPEN_ORDERS:
      return {
        ...state,
        availableOpenOrders: payload,
      }
    case orderbookActionTypes.SET_BUY_ORDERS:
      return {
        ...state,
        buyOrders: payload,
      }
    case orderbookActionTypes.SET_SELL_ORDERS:
      return {
        ...state,
        sellOrders: payload,
      }
    case orderbookActionTypes.SET_TRADE_HISTORY:
      return {
        ...state,
        tradeHistory: payload,
      }
    case orderbookActionTypes.SET_TRADING_PAIR:
      return {
        ...state,
        tradingPair: payload,
      }

    case orderbookActionTypes.SET_CHART_STATS_REQUEST:
      return {
        ...state,
        chartStats: { loading: true },
      }
    case orderbookActionTypes.SET_CHART_STATS_SUCCESS:
      return {
        ...state,
        chartStats: { loading: false, stats: action.payload },
      }
    case orderbookActionTypes.SET_CHART_STATS_FAILURE:
      return {
        ...state,
        chartStats: { loading: false, error: action.payload },
      }

    case orderbookActionTypes.SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: payload,
      }
    case orderbookActionTypes.SET_EXECUTED_ORDERS:
      return {
        ...state,
        executedOrders: payload,
      }

    case orderbookActionTypes.SET_EXECUTED_ORDERS_SOURCE:
      return {
        ...state,
        executedOrdersSrc: action.payload,
      }

    default:
      return state
  }
}
