import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 0.5px solid #ffffff20;
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`
export const IconWrapper = styled.div`
  width: 1rem;
  margin-right: 10px;
`
export const Input = styled.input`
  font-size: 1.6rem;
  color: white;
  width: 100%;
`
