import Dec from "decimal.js"

export const displayFloats = (num = 0, maxDigits = 2) => +num.toFixed(maxDigits)

export const specifyDecimals = (value, decimals) =>
  // @ts-ignore
  Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)

export const formatNumber = (_num = 0, decimals = 2, removeTrailingZeroes = true, overideDecimals = false) => {
  // May be a better way to handle this Mo. Added because Meir wanted different decimal points depending on the number
  try {
    let overiddenDecimals = null
    if (_num < 1) return specifyDecimals(_num, 4)
    if (_num > 1000) overiddenDecimals = 1
    else if (_num < 1000) return specifyDecimals(_num, 2)

    if (!Number.isFinite(parseFloat('' + _num))) return '0'
    const decimalsToUse = overideDecimals ? overiddenDecimals : decimals
    const sign = parseFloat('' + _num) < 0 ? '-' : ''
    const num = Math.abs(parseFloat('' + _num))
    if (num < 1000) return displayFloats(_num, decimalsToUse)

    const si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'K' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    let i

    for (i = si.length - 1; i > 0; i -= 1) {
      if (num >= si[i].value) {
        break
      }
    }

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    return (
      sign +
      new Dec(num / si[i].value)
        .toFixed(decimalsToUse)
        .replace(rx, '$1') +
      si[i].symbol
    )
  } catch (e) {
    return _num.toString()
  }
}