import { useCallback } from 'react'
import * as S from './styles'

export type DropdownItemProps = {
  title: number | string
  searchTerm: number | string
  handleAction: (select: number | string, address: number | string) => void
}
const DropdownItem = ({ title, handleAction, searchTerm }: DropdownItemProps) => {
  const onHandleAction = useCallback(() => {
    handleAction(title, searchTerm)
  }, [searchTerm, title])

  return <S.Wrapper onClick={onHandleAction}>{title}</S.Wrapper>
}

export default DropdownItem
