import React from 'react'
import { Flex } from 'styles/layouts'
import ThreeDotsLoader from '../ThreeDotsLoader'
import * as S from './styles'

export type NavbarItemPops = {
  label?: string
  info?: string | number
  type?: string
  infoStyle?: object
  loading?: boolean
}
const NavbarItem = ({ label, info, infoStyle, loading }: NavbarItemPops) => (
  <S.Wrapper>
    <Flex gap="0.3rem">
      <S.Label>{label}</S.Label>
    </Flex>
    {loading ? <ThreeDotsLoader style={{ top: '8px' }} /> : <S.Info style={infoStyle}>{info}</S.Info>}
  </S.Wrapper>
)

export default NavbarItem
