import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { fetchExecutedOrders, fetchAllOrders, setBuyOrders, setSellOrders } from 'store/actions/orderbookActions'
import { usePromise } from 'hooks/usePromise'
import { connectToCurrentHPoolTokenContract } from 'store/actions/contractActions'
import { SET_EXECUTED_ORDERS, SET_PENDING_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import { getDetailedOrderInfo, mapToFitDapiModel, setupWeb3EventListener } from 'utils/tradeOrdersService'
import { setOpenOrders } from 'store/actions/transactionActions'
import { newGetContractAddress } from 'utils/contractReader'
import { setTradingPairsInformation } from 'store/actions/tradingPairActions'
import Header from 'components/general/Header/Header'
import { arraySort } from 'utils/arraySort'
import Graph from './blocks/Graph'
import MarketOrder from './blocks/MarketOrder'
import Navbar from './blocks/Navbar'
import Transactions from './blocks/Transactions'
import * as S from './styles'
import OrderBook from './blocks/OrderBook'
import { ITransactionData } from './blocks/Transactions/ITransactions'
import BalanceUpdater from './blocks/BalanceUpdater'
import { fetchAppConfig } from '../../../store/actions/generalActions'
import OrdersEventsListeners from './blocks/Transactions/EventsListeners'

function Dashboard({
  account,
  blockchainApi,
  setBuyOrders,
  setSellOrders,
  buyOrders,
  sellOrders,
  currentHPoolTokenContract,
  currentHPoolToken,
  connectToCurrentHPoolTokenContract,
  setTradingPairsInformation,
  openOrders,
  setOpenOrders,
}) {
  const { pendingOrders = [], executedOrders = [] } = useSelector((state: any) => ({
    pendingOrders: state.orderbook.pendingOrders,
    executedOrders: state.orderbook.executedOrders,
  }))

  const dispatch = useDispatch()
  const [volume, setVolume] = useState(0)
  const [blockPrice, setBlockPrice] = useState('0.00')
  const [high, setHigh] = useState(0)
  const [low, setLow] = useState(0)
  const [lastTradePrice, setLastTradePrice] = useState(0)
  const [lastTradePriceType, setLastTradePriceType] = useState<'AskLimit' | 'BidLimit'>('AskLimit')
  const [newTrade, setNewTrade] = useState<ITransactionData[]>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [, matchingMarketContractAddress] = usePromise(() => newGetContractAddress('MatchingMarket'), { initReq: true })

  const removeTransactionsOrder = (id: string) => console.log(`remove transaction${id}`)

  const currentTokenExcecutedOrders =
    executedOrders?.filter((order) => order?.coinAddress === currentHPoolToken?.contract_address) || []
  const sortedOrdersByTimestamp = arraySort(currentTokenExcecutedOrders, 'ts', { isAsc: false, isString: false }) // 1st item is latest order last item is oldest
  const latestTradePrice = sortedOrdersByTimestamp?.[0]?.price
  const latestTradeType = sortedOrdersByTimestamp?.[0]?.side

  useEffect(() => {
    setVolume(0)
    setBlockPrice('0.00')
    setHigh(0)
    setLow(0)
    setLastTradePrice(0)
    setLastTradePriceType('AskLimit')
    setNewTrade([])
  }, [setTradingPairsInformation])

  useEffect(() => {
    setTradingPairsInformation()
  }, [setTradingPairsInformation])

  useEffect(() => {
    if (currentHPoolToken) {
      connectToCurrentHPoolTokenContract(currentHPoolToken.address)
    }
  }, [currentHPoolToken, connectToCurrentHPoolTokenContract, account])

  useEffect(() => {
    if (currentHPoolTokenContract) {
      setBuyOrders(currentHPoolToken)
      setSellOrders(currentHPoolToken)
    }
  }, [currentHPoolTokenContract, setBuyOrders, setSellOrders])

  useEffect(() => {
    dispatch(fetchAppConfig())
    dispatch(fetchAllOrders())
  }, [])

  useEffect(() => setOpenOrders([...buyOrders, ...sellOrders]), [buyOrders, sellOrders, account])

  return (
    <S.Wrapper>
      <S.WrapperMain>
        <S.PageTitle>Hord DEX</S.PageTitle>
        <Navbar
          account={account}
          lastTradePrice={latestTradePrice}
          lastTradePriceType={lastTradePriceType}
          blockValues={{ volume, high, low, blockPrice }}
        />
        <S.WrapperGraph marketActive={false}>
          <Graph />
          <OrderBook
            orderBookAsks={sellOrders}
            orderBookBids={buyOrders}
            latestTransaction={latestTradePrice}
            latestTransactionType={latestTradeType}
          />
          <MarketOrder
            setOpenOrder={(order) => setOpenOrders([order])}
            setActiveIndex={(index) => setActiveIndex(index)}
            blockchainApi={blockchainApi}
          />
        </S.WrapperGraph>
        <Transactions
          newTradeData={newTrade}
          data={[]}
          openOrderData={openOrders}
          activeIndex={activeIndex}
          setActiveIndex={(index) => setActiveIndex(index)}
          remove={removeTransactionsOrder}
        />
      </S.WrapperMain>
      <BalanceUpdater />
      <OrdersEventsListeners />
      {/* <Toast /> */}
    </S.Wrapper>
  )
}

const mapStateToProps = (state) => ({
  account: state.wallet.account,
  currentHPoolTokenContract: state.contracts.currentHPoolTokenContract,
  buyOrders: state.orderbook.buyOrders,
  sellOrders: state.orderbook.sellOrders,
  currentHPoolToken: state.tradingPair.currentHPoolToken,
  openOrders: state.transactions.openOrders,
})

export default connect(mapStateToProps, {
  setBuyOrders,
  setSellOrders,
  connectToCurrentHPoolTokenContract,
  setTradingPairsInformation,
  setOpenOrders,
})(Dashboard)
