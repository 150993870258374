import { contractReader } from '../../../../../utils/contractReader'
import { createContract, getContractsMeta } from '../../../../../utils/contractsRegistryService'
import ERC20 from '../../../../../utils/contracts/ERC20.json'
import configJson from '../../../../../config/config.json'

export const loadHPools = async (HPoolFactory, HPoolManager) => {
  const generateTokenIdHexForNftAddress = (tokenId) => {
    const hexString = Number(tokenId).toString(16)

    const missingZeros = 64 - hexString.length

    const hexTokenId = '0'.repeat(missingZeros) + hexString

    return hexTokenId
  }

  if (HPoolFactory) {
    const startIndex = 0
    const endIndex = await contractReader(HPoolFactory, 'getEndIndexForDeployedHPools')
    const hPools = await contractReader(HPoolFactory, 'getDeployedHPools', [startIndex, endIndex])
    const { HPool } = await getContractsMeta()
    if (hPools.length > 0) {
      const hPoolIds = await Promise.all(
        hPools.map(async (address) => {
          const contract = createContract(address, HPool.abi)
          const info = await contractReader(contract.methods, 'hPool')

          return { hpoolId: info.hPoolId, address }
        }),
      )

      const hPoolsInfo = await Promise.all(
        hPoolIds.map(async (hPool) => {
          const hPoolInfo = await contractReader(HPoolManager, 'getPoolInfo', [hPool.hpoolId])
          return { ...hPool, nftId: hPoolInfo[4], phase: hPoolInfo[0] }
        }),
      )

      let addedNftImages = await Promise.all(
        hPoolsInfo.map(async (hPoolInfo) => {
          const id = generateTokenIdHexForNftAddress(hPoolInfo.nftId)
          const url = new URL(`${configJson.apiPath}/hpool_nft/metadata?id=${id}`)
          const nftMetadata = await fetch(url).then((res) => res.json())

          if (nftMetadata) return { ...hPoolInfo, image: nftMetadata?.image_s3 }
          return undefined
        }),
      )

      addedNftImages = addedNftImages.filter((hpool) => hpool !== undefined)

      const addTokenNameAndSymbol = await Promise.all(
        addedNftImages.map(async (hPoolInfo) => {
          const contract = createContract(hPoolInfo.address, ERC20.abi)
          const symbol = await contractReader(contract.methods, 'symbol')
          const name = await contractReader(contract.methods, 'name')

          return { ...hPoolInfo, symbol, name }
        }),
      )

      const config = {
        method: 'GET',
        headers: { Authorization: 'Bearer GUEST' },
      }
      const createParms = (hpool) =>
        new URLSearchParams({
          nft_id: hpool.nftId,
          chain_id: window.networkId,
        }).toString()
      const hPoolsWithNftImage = await Promise.all(
        addTokenNameAndSymbol.map(async (hpool) => {
          const url = new URL(`${configJson.apiPath}/champion/nft?${createParms(hpool)}`)
          const nftImage = await fetch(url, config).then((res) => res.json())
          return { ...hpool, ...nftImage }
        }),
      )
      return hPoolsWithNftImage
    }
  }
}
