import * as S from './styles'

export type CheckboxPropx = {
  title: string
  checked?: boolean
  action?: () => void
  backgroundColor?: 'red' | 'green'
}

const Checkbox = ({ title, checked, action, backgroundColor }: CheckboxPropx) => (
  <S.Label>
    <S.Input backgroundColor={backgroundColor} type="checkbox" checked={checked} onClick={action} onChange={action} />
    <S.Span />
    <S.Title>{title}</S.Title>
  </S.Label>
)

export default Checkbox
