import React from 'react'
import { connect } from 'react-redux'
import { resetIdCounter } from 'react-tabs'
import MarketOrderAction from '../../../../../components/general/MarketOrderAction'
import * as S from './styles'

const MarketOrder = ({ setOpenOrder, blockchainApi, setActiveIndex, currentHPoolTokenSymbol }) => {
  const orderType = 'Limit Order'

  resetIdCounter()

  return (
    <S.Section>
      <MarketOrderAction
        currentHPoolTokenSymbol={currentHPoolTokenSymbol}
        type="Buy"
        setOpenOrder={setOpenOrder}
        orderType={orderType}
        blockchainApi={blockchainApi}
        setActiveIndex={setActiveIndex}
      />
      <MarketOrderAction
        currentHPoolTokenSymbol={currentHPoolTokenSymbol}
        type="Sell"
        setOpenOrder={setOpenOrder}
        orderType={orderType}
        blockchainApi={blockchainApi}
        setActiveIndex={setActiveIndex}
      />
    </S.Section>
  )
}

const mapStateToProps = (state) => ({
  currentHPoolTokenSymbol: state.tradingPair.currentHPoolToken?.symbol,
})

export default connect(mapStateToProps)(MarketOrder)
