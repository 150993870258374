import styled, { css } from 'styled-components'

import { InputProps } from '.'

const inputModifier = {
  Flex: () => css`
    display: flex;
    flex-direction: row;
  `,
}

export const Wrapper = styled.div`
  &:first-of-type {
    margin-right: 1.5rem;

    @media only screen and (max-width: 350px) {
      margin-right: unset;
    }
  }
`
export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
`
export const Label = styled.label`
  color: #969799;
  img {
    width: 1.1rem;
    height: 1.1rem;
    margin-right: 0.2rem;
  }
`
export const Box = styled.div<Partial<InputProps>>`
  ${({ theme, inputInfo, fullWidth }) => css`
    margin: 1rem 0;
    align-items: center;
    border-bottom: 0.1rem solid ${theme.colors.inputBorder};
    width: ${fullWidth ? '100%' : '20rem'};
    ${inputInfo && inputModifier.Flex()}
    :hover {
      border-bottom-color: ${theme.colors.white};
    }
  `}
`
export const Span = styled.span`
  color: white;
  font-weight: 600;
`

export const Input = styled.input`
  width: 0;
  flex: 1;
  padding: 1rem 1rem 1rem 0;
  color: white;
  font-size: 1.6rem;
`
