import LoadableContent from 'components/general/LoadableContent'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { isMatched } from 'utils/validator'
import { getPercentage } from 'utils/math'
import { Box, Flex } from 'styles/layouts'
import theme from 'styles/theme'
import { handleUserTimezone } from 'utils/date'
import { createTimeStages, getDateTime, getDayAndMonth } from 'utils/time'
import { getChartStatsAction } from 'store/actions/orderbookActions'
import { getCandelInfo, getTs } from 'utils/array'
import { SET_EXECUTED_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import CustomChart from '../Chart/ApexChart'
import * as S from './styles'
import TimeRange from './TimeRange'
import { ITab } from './TimeRange/Tab'

const TABS: ITab[] = [
  { label: '1D', timeRange: 60 * 60 * 2, id: 0, stages: 12, parseDate: getDateTime },
  { label: '3M', timeRange: 24 * 60 * 60 * 2.4 * 3, id: 1, stages: 12, parseDate: getDayAndMonth },
  { label: '1Y', timeRange: 24 * 60 * 60 * 2.4 * 12, id: 2, stages: 10, parseDate: getDayAndMonth },
]

const now = Date.now()

const Graph = ({ poolAddress }) => {
  const dispatch = useDispatch()
  const { stats: _ = [], loading: __ } = useSelector((state: any) => state.orderbook.chartStats) || {}
  const loading = false
  const { stats: rawStats, poolTokenContract } = useSelector((state: any) => ({
    stats: state.orderbook.executedOrders || [],
    poolTokenContract: state.tradingPair?.currentHPoolToken?.contract_address || '',
  }))

  const stats = useMemo(
    () => rawStats.filter((stat) => isMatched(stat.coinAddress, poolTokenContract)),
    [poolTokenContract, rawStats],
  )

  const [chartMounted, setChartMounted] = useState(false)
  const [selectedTabOption, setSelectedTabOption] = useState(TABS[0])
  const creationDate = stats?.[0]?.ts
  const lastStat = stats?.[stats?.length - 1] || { id: 0 }

  const appendTestStat = () => {
    const newNow = lastStat.ts + 3600000
    console.log({ __newNow: new Date(newNow) })

    const newStat = {
      ...lastStat,
      ts: newNow,
      price: lastStat.hpool_token_price + 0.3,
      hpool_token_price: lastStat.hpool_token_price + 0.3,
    }

    dispatch({ type: SET_EXECUTED_ORDERS, payload: [...stats, newStat] })
  }

  const timeStages = useMemo(
    () =>
      createTimeStages({
        now: lastStat?.ts || Date.now(),
        creationDateTs: creationDate,
        timeDiff: selectedTabOption.timeRange,
        length: selectedTabOption.stages,
        dateFormatMethod: () => {},
      }),
    [selectedTabOption, lastStat.ts],
  )

  const findTab = (id) => TABS.find((tab) => tab.id === id)

  const updateTimeStage = (id) => setSelectedTabOption(findTab(id))

  const haveSamePrice = (prices = []) => prices.length && prices.every((price) => price === prices[0])

  const getPrices = useCallback(
    (index) => {
      const diff = timeStages[1].ts - timeStages[0].ts
      const extraStage = {
        ts: timeStages[timeStages.length - 1].ts + diff,
        date: new Date(timeStages[timeStages.length - 1].ts + diff),
      }
      const timeStagesWithExtra = [...timeStages, extraStage]

      const min = timeStages[index].ts
      const currentStageDate = timeStagesWithExtra[index + 1].ts

      const rangedPrices = stats.filter((stat) => stat.ts <= currentStageDate && stat.ts > min)

      const candelInfo = getCandelInfo(rangedPrices, 'hpool_token_price')
      // convert to empty array if it has the same price
      // candelInfo = haveSamePrice(candelInfo) ? [] : candelInfo

      return candelInfo
    },
    [stats, timeStages],
  )

  const data = useMemo(
    () =>
      timeStages.map((timeStage, index) => ({
        y: getPrices(index),
        x: timeStage.date,
      })),
    // .filter((elem) => elem.y.length)
    [timeStages, getPrices],
  )

  useEffect(() => {
    if (poolAddress) dispatch(getChartStatsAction(poolAddress))
  }, [poolAddress])

  const handleChartMount = () => setChartMounted(true)

  const prices = useMemo(() => data.map((elem) => elem?.y).flat() || [], [JSON.stringify(data)])

  const max = Math.max(...prices) || 0
  const min = Math.min(...prices) || 0

  const offsetPercentage = getPercentage(5, min) || 0 // in case minPrice === maxPrice
  const offsetValue = (max - min) / 10 || offsetPercentage

  const maxPrice = useMemo(() => Math.max(...prices) + offsetValue, [JSON.stringify(data)])
  const minPrice = useMemo(() => Math.min(...prices) - offsetValue, [JSON.stringify(data)])

  return (
    <S.Wrapper>
      <LoadableContent
        noData={!loading && !data.length}
        renderNoData={<span>No Data found For this Time range</span>}
        loading={loading && chartMounted}
        size={60}
      >
        <Flex column>
          <Box
            bg={theme.colors.chartBg}
            py={theme.spacings.xxsmall}
            px={theme.spacings.xsmall}
            // border="0.1px solid rgba(221,211,211,0.2)"
            borderRadius={5}
          >
            {/* <button style={{ padding: '4rem', color: '#fff' }} type="button" onClick={appendTestStat}>
              Add More
            </button> */}
            <TimeRange tabs={TABS} selectedTabOption={selectedTabOption} updateTimeStage={updateTimeStage} />
          </Box>
          <CustomChart min={minPrice} max={maxPrice} data={data} onMount={handleChartMount} />
        </Flex>
      </LoadableContent>
    </S.Wrapper>
  )
}

const mapStateToProps = ({ orderbook, tradingPair }) => ({
  stats: orderbook?.chartStats?.stats || [],
  poolAddress: tradingPair.currentHPoolToken?.address,
})
export default connect(mapStateToProps)(Graph)
