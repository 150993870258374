import { vpoolAddressUrlParam } from 'utils/constants/vpool'
import { getSearchParam } from 'utils/urlParams'
import { loadHPools } from 'views/dashboard/components/blocks/Navbar/loadHPools'
import { loadVPools } from 'views/dashboard/components/blocks/Navbar/loadVPools'
import * as tradingPairActionTypes from '../actionTypes/tradingPairActionTypes'
import { startAction, stopAction } from './uiActions'

export const setTradingPairsInformation = (HPoolFactory, HPoolManager) => async (dispatch) => {
  if (HPoolFactory) {
    dispatch(startAction())

    const newHPoolList = loadHPools(HPoolFactory, HPoolManager) || []
    const newVPoolList = loadVPools(HPoolFactory) || []

    let mergedPoolList = await Promise.allSettled([newHPoolList, newVPoolList])
    mergedPoolList = mergedPoolList
      .map((poolRes) => poolRes.value)
      .flat()
      .filter((pool) => !!pool)

    const getTokenIndex = (hpoolList, tokenAddress) => hpoolList.findIndex((hpool) => hpool.address === tokenAddress)
    const tokenAddressFromPathname = getSearchParam(vpoolAddressUrlParam)

    // Temporariliy hide pools hidden on Hord app. To be removed at some point
    const tempUpdatedPoolList = mergedPoolList?.filter((pool) => !pool?.name?.startsWith('Hord.app'))

    if (tempUpdatedPoolList?.length > 0) {
      dispatch({
        type: tradingPairActionTypes.SET_HPOOL_TOKENS_LIST,
        payload: tempUpdatedPoolList,
      })

      const tokenIndex = getTokenIndex(tempUpdatedPoolList, tokenAddressFromPathname)
      if (tokenIndex > 0) {
        dispatch({
          type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
          payload: tempUpdatedPoolList[tokenIndex],
        })
      } else {
        dispatch({
          type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
          payload: tempUpdatedPoolList[0],
        })
      }
      dispatch(stopAction())
    }
    dispatch(stopAction())
  }
}

export const changeCurrentHPoolToken = (hpoolAddress) => async (dispatch, getState) => {
  const hPoolToken = getState().tradingPair?.hPoolTokensList?.filter(
    (hPoolToken) => hPoolToken?.address === hpoolAddress,
  )
  dispatch({
    type: tradingPairActionTypes.SET_CURRENT_HPOOL_TOKEN,
    payload: hPoolToken[0],
  })
}
