import styled from 'styled-components'
import { generateMedia } from 'styled-media-query'

const customMedia = generateMedia({
  custom: '550px',
})

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  ${customMedia.lessThan('custom')`
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  `}
`

export const WrapperCoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const WrapperExchange = styled.div`
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Label = styled.span`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.5;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
`

export const DropDownItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const EmptyImg = styled.div`
  width: 4rem;
  aspect-ratio: 1 / 1;
  margin: 0 1rem;
  border-radius: 50%;
  background: #0D0D0D;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  border-radius: 50%;
  background: #0D0D0D;
`
export const Name = styled.p``

export const EmptySearch = styled.p`
  margin: 1rem 0;
  text-align: center;
`

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  margin: 0 1rem;
  border-radius: 50%;
  background: #0D0D0D;
`
