import React, { useCallback } from 'react'
import { Flex } from 'styles/layouts'
import theme from 'styles/theme'
import { ITab, Tab } from './Tab'

interface ITimeRange {
  tabs: ITab[]
  updateTimeStage: (id: number) => void
  selectedTabOption: ITab
}

const TimeRange = ({ tabs = [], selectedTabOption, updateTimeStage }: ITimeRange) => {
  const handleClick = useCallback(
    (id) => {
      updateTimeStage(id)
    },
    [updateTimeStage],
  )

  const isSelected = useCallback((tab) => tab.id === selectedTabOption.id, [updateTimeStage])

  return (
    <Flex gap={theme.spacings.xsmall}>
      {tabs.map((tab) => (
        <Tab key={tab.label} {...tab} onClick={handleClick} active={isSelected(tab)} />
      ))}
    </Flex>
  )
}

export default TimeRange
