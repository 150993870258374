import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changeCurrentHPoolToken } from 'store/actions/tradingPairActions'
import { IPairs } from 'views/dashboard/components/blocks/Navbar'
import { fromObjToParam } from 'utils/urlParams'
import { vpoolAddressUrlParam } from 'utils/constants/vpool'
import { switchNetwork } from '../../../store/actions/walletActions'
import Dropdown from '../Dropdown'
import DropdownItem from '../DropdownItem'
import Icon from '../Icon'
import * as S from './styles'
import config from '../../../config/config.json'
import ThreeDotsLoader from '../ThreeDotsLoader'
import SearchInput from '../SearchInput'

export type NavbarPairProps = {
  coin?: string
  pairs?: IPairs[]
  currentHPoolTokenName: string
  changeCurrentHPoolToken: (hPoolTokenName: string) => void
  currentHPoolTokenImage: string
  connected: boolean
  switchNetwork: (payload: object) => void
  loading: boolean
}

const NavbarPair = ({
  coin,
  pairs,
  currentHPoolTokenName,
  changeCurrentHPoolToken,
  currentHPoolTokenImage,
  connected,
  switchNetwork,
  loading,
}: NavbarPairProps) => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const [state, setState] = useState(currentHPoolTokenName)
  const [dropdownState, setDropdownState] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    if (currentHPoolTokenName) {
      setState(currentHPoolTokenName)
    }
  }, [currentHPoolTokenName])

  const handleChange = useCallback(
    (select: string, address: string) => {
      setDropdownState(false)

      setState(select)
      changeCurrentHPoolToken(address)
      const newUrl = pathname + fromObjToParam({ [vpoolAddressUrlParam]: address })
      push(newUrl)
    },
    [changeCurrentHPoolToken, setDropdownState, setState],
  )

  const handleDropdownClick = useCallback(
    (newDropdownState) => {
      if (connected) {
        setDropdownState(newDropdownState)
      }
    },
    [setDropdownState, connected],
  )

  const handleSearchChange = (inputValue) => {
    setSearchInput(inputValue)
  }

  const filteredPairs = pairs?.filter(({ name }) => name.toLowerCase().includes(searchInput.toLowerCase()))

  return (
    <S.Wrapper className="navbar-pair">
      <S.WrapperCoin>
        <S.Label>Coin</S.Label>

        {loading && <ThreeDotsLoader style={{ top: '0', left: '38px', zIndex: 1000 }} />}
        {currentHPoolTokenImage && !loading ? <S.Image src={currentHPoolTokenImage} /> : <S.EmptyImg />}

        <Dropdown title={state} active={dropdownState} setDropdownState={handleDropdownClick}>
          <>
            <SearchInput placeholder="Search" type="text" value={searchInput} setValue={handleSearchChange} />
            {filteredPairs.length > 0 ? (
              filteredPairs.map(({ image, name, address, id }) => (
                <S.DropDownItem key={id}>
                  <S.Image src={image} />
                  <DropdownItem title={name} searchTerm={address} handleAction={handleChange} />
                </S.DropDownItem>
              ))
            ) : (
              <S.EmptySearch>No search matches</S.EmptySearch>
            )}
          </>
        </Dropdown>
      </S.WrapperCoin>

      <S.WrapperExchange>
        <Icon name="ArrowGreenLeft" />
        <Icon name="ArrowGreenLeft" style={{ transform: 'rotate(180deg)' }} />
      </S.WrapperExchange>
      <S.WrapperCoin>
        <S.Label>Pair</S.Label>
        <S.IconWrapper>
          <Icon style={{ width: '100%' }} name={coin} ext="png" folder="images" />
        </S.IconWrapper>
        <S.Container>
          <S.Name>{coin}</S.Name>
        </S.Container>
      </S.WrapperCoin>
    </S.Wrapper>
  )
}

const mapStateToProps = (state) => ({
  currentHPoolTokenName: state.tradingPair.currentHPoolToken?.name,
  currentHPoolTokenImage: state.tradingPair.currentHPoolToken?.image,
  connected: !!state.wallet.account,
  loading: state.ui.loading,
})

export default connect(mapStateToProps, {
  changeCurrentHPoolToken,
  switchNetwork,
})(NavbarPair)
