import { contractReader, newGetContractAddress } from 'utils/contractReader'
import { ethToWei, WeiToEth } from 'utils/web3Service'
import moment from 'moment'
import { isAddressApprovedOnAsset } from 'utils/assetsService'
import callTx from 'utils/txService'
import { fetchChartStats } from 'utils/orderbookService'
import {
  getDetailedOrderInfo,
  getPrevOpenOrders,
  appendOrderId,
  getPrevCompletedOrders,
} from 'utils/tradeOrdersService'
import * as orderbookActionTypes from '../actionTypes/orderbookActionTypes'
import { clearInputs } from './inputActions'
import { createNotification, setBuyLoading, setSellLoading } from './uiActions'
import { setBalance } from './balanceActions'

export const setBuyOrders = (HPoolToken) => async (dispatch, getState) => {
  const { MatchingMarket } = getState().contracts
  const { BUSD } = getState().contracts
  const { MakerOtcSupportMethods } = getState().contracts

  const sellTokenAddress = BUSD.address
  const buyTokenAddress = HPoolToken.address

  const buyOffers = await contractReader(MakerOtcSupportMethods, 'getOffers(address,address,address)', [
    MatchingMarket.address,
    sellTokenAddress,
    buyTokenAddress,
  ])
  if (!buyOffers.error) {
    const buyOrders = []
    let currentId = buyOffers.ids[0] // to check if we have a valid tradeOrder
    let currentIndex = 0

    while (currentId !== '0') {
      const buyAmt = Number(WeiToEth(buyOffers.buyAmts[currentIndex]))
      const payAmt = Number(WeiToEth(buyOffers.payAmts[currentIndex]))
      const id = buyOffers.ids[currentIndex]
      const owner = buyOffers.owners[currentIndex]

      const timestamp = buyOffers.timestamps[currentIndex]
      const date = moment.unix(timestamp).format('MMM DD YYYY hh:mm:ss')
      const buyOrder = {
        buyAmt,
        payAmt,
        id,
        owner,
        date,
        coin: HPoolToken.name,
        coinImage: HPoolToken.image,
        price: payAmt / buyAmt,
        amount: buyAmt,
        total: payAmt,
        side: 'buy',
        loading: false,
        contractAddress: HPoolToken.address,
      }
      buyOrders.push(buyOrder)

      currentIndex += 1
      currentId = buyOffers.ids[currentIndex]
    }

    dispatch({
      type: orderbookActionTypes.SET_BUY_ORDERS,
      payload: buyOrders,
    })
  } else {
    dispatch(createNotification('error', 'Something went wrong with the tx', 4000))
    console.warn(buyOffers.error)
  }
}

export const fetchExecutedOrders = () => async (dispatch) => {
  try {
    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
    const allPrevLogTakes = (await getPrevCompletedOrders()) || []

    let formattedOpenOrders = await Promise.all(
      allPrevLogTakes.map((ev) => getDetailedOrderInfo(ev, matchingMarketContractAddress)),
    )
    formattedOpenOrders = formattedOpenOrders.map((detailedInfo, index) => ({
      ...allPrevLogTakes[index],
      ...detailedInfo,
    }))
    const allOrders = await Promise.all(formattedOpenOrders.map(appendOrderId))

    dispatch({ type: orderbookActionTypes.SET_EXECUTED_ORDERS, payload: allOrders })
  } catch (error) {
    console.log({ fetchExecutedOrdersError: error })
  }
}

const fetchOpenOrders = () => async (dispatch) => {
  const openOrders = (await getPrevOpenOrders()) ?? []
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

  let formattedOpenOrders = await Promise.all(
    openOrders.map((ev) => getDetailedOrderInfo(ev, matchingMarketContractAddress)),
  )
  formattedOpenOrders = formattedOpenOrders.map((detailedInfo, index) => ({ ...openOrders[index], ...detailedInfo }))
  dispatch({ type: orderbookActionTypes.SET_PENDING_ORDERS, payload: formattedOpenOrders })
}

export const fetchAllOrders = () => async (dispatch) => {
  try {
    await dispatch(fetchOpenOrders())
    await dispatch(fetchExecutedOrders())
  } catch (error) {
    console.log({ fetchAllOrdersErr: error })
  }
}

export const setSellOrders = (HPoolToken) => async (dispatch, getState) => {
  const { MatchingMarket } = getState().contracts
  const { BUSD } = getState().contracts
  const { MakerOtcSupportMethods } = getState().contracts

  const sellTokenAddress = HPoolToken.address
  const buyTokenAddress = BUSD.address

  const sellOffers = await contractReader(MakerOtcSupportMethods, 'getOffers(address,address,address)', [
    MatchingMarket.address,
    sellTokenAddress,
    buyTokenAddress,
  ])
  if (!sellOffers.error) {
    const sellOrders = []
    let currentId = sellOffers.ids[0] // to check if we have a valid tradeOrder
    let currentIndex = 0

    while (currentId !== '0') {
      const buyAmt = Number(WeiToEth(sellOffers.buyAmts[currentIndex]))
      const payAmt = Number(WeiToEth(sellOffers.payAmts[currentIndex]))
      const id = sellOffers.ids[currentIndex]
      const owner = sellOffers.owners[currentIndex]
      const timestamp = sellOffers.timestamps[currentIndex]
      const date = moment.unix(timestamp).format('MMM DD YYYY hh:mm:ss')

      const sellOrder = {
        buyAmt,
        payAmt,
        id,
        owner,
        date,
        coin: HPoolToken.name,
        coinImage: HPoolToken.image,
        price: buyAmt / payAmt,
        amount: payAmt,
        total: buyAmt,
        side: 'sell',
        loading: false,
        contractAddress: HPoolToken.address,
      }

      sellOrders.push(sellOrder)

      currentIndex += 1
      currentId = sellOffers.ids[currentIndex]
    }

    dispatch({
      type: orderbookActionTypes.SET_SELL_ORDERS,
      payload: sellOrders,
    })
  } else {
    dispatch(createNotification('error', 'Something went wrong with the tx', 4000))
    console.warn(sellOffers.error)
  }
}

export const makeBuyOrder = (busdAmount, hPoolTokenAmount) => async (dispatch, getState) => {
  if (busdAmount <= 0 || hPoolTokenAmount <= 0)
    return dispatch(createNotification('error', 'Please enter an amount greater than 0', 4000))
  try {
    dispatch(setBuyLoading())
    // check allowance of matching market address on busd token
    const signerAddress = getState().wallet.account
    const busdContract = getState().contracts.BUSD
    const { currentHPoolTokenContract } = getState().contracts
    const matchingMarketContract = getState().contracts.MatchingMarket
    const isTransactionAllowed = await isAddressApprovedOnAsset(
      signerAddress,
      matchingMarketContract.address,
      busdContract,
      busdAmount,
    )

    // convert values to wei
    const busdAmountInWei = ethToWei(busdAmount)
    const hPoolTokenAmountInWei = ethToWei(hPoolTokenAmount)

    const busdBalanceFromWalletAddress = await contractReader(busdContract, 'balanceOf', [signerAddress])
    // If user tries to make a tx with more BUSD than they have in their wallet, throw an error
    if (+busdBalanceFromWalletAddress < +busdAmountInWei) {
      throw new Error('You do not have enough BUSD to complete this transaction')
    }

    // if not enough allowance approve matching market address on busd contract
    await callTx(busdContract, 'approve', [matchingMarketContract.address, busdAmountInWei], { from: signerAddress })
    await callTx(
      matchingMarketContract,
      'offer(uint256,address,uint256,address,uint256)',
      [busdAmountInWei, busdContract.address, hPoolTokenAmountInWei, currentHPoolTokenContract.address, 0],
      { from: signerAddress },
    )

    // update tradingPairs
    setTimeout(() => {
      const { currentHPoolToken } = getState().tradingPair
      dispatch(setBuyOrders(currentHPoolToken))
      dispatch(setSellOrders(currentHPoolToken))
      dispatch(setBalance(busdContract, 'busdBalance'))
      dispatch(setBalance(currentHPoolTokenContract, 'currentHPoolTokenBalance'))
      dispatch(setBuyLoading())
      dispatch(clearInputs())
    }, 3000)
  } catch (error) {
    // CATCH
    console.log(error)
    dispatch(setBuyLoading())
    dispatch(clearInputs())
    dispatch(createNotification('error', error.message, 4000))
  }
}

export const makeSellOrder = (busdAmount, hPoolTokenAmount, busdFee) => async (dispatch, getState) => {
  if (busdAmount <= 0 || hPoolTokenAmount <= 0)
    return dispatch(createNotification('error', 'Please enter an amount greater than 0', 4000))
  try {
    dispatch(setSellLoading())
    // check allowance of matching market address on busd token
    const signerAddress = getState().wallet.account
    const busdContract = getState().contracts.BUSD
    const { currentHPoolTokenContract } = getState().contracts
    const matchingMarketContract = getState().contracts.MatchingMarket
    const isCurrentTokenApproved = await isAddressApprovedOnAsset(
      signerAddress,
      matchingMarketContract.address,
      currentHPoolTokenContract,
      hPoolTokenAmount,
    )

    const isBusdFeeApproved = await isAddressApprovedOnAsset(
      signerAddress,
      matchingMarketContract.address,
      busdContract,
      busdFee,
    )

    const currentHpoolTokenSymbol = await contractReader(currentHPoolTokenContract, 'symbol')
    const hpoolTokenBalanceFromWalletAddress = await contractReader(currentHPoolTokenContract, 'balanceOf', [
      signerAddress,
    ])

    // convert values to wei
    const busdAmountInWei = ethToWei(busdAmount)
    const busdFeeInWei = ethToWei(busdFee)
    const hPoolTokenAmountInWei = ethToWei(hPoolTokenAmount)

    // If user doesnt have enough hpool tokens, throw error and notify them
    if (+hpoolTokenBalanceFromWalletAddress < +hPoolTokenAmountInWei) {
      throw new Error(`You do not have enough ${currentHpoolTokenSymbol} tokens`)
    }

    // if not enough allowance approve matching market address on busd contract
    await callTx(currentHPoolTokenContract, 'approve', [matchingMarketContract.address, hPoolTokenAmountInWei], {
      from: signerAddress,
    })

    if (!isBusdFeeApproved) {
      await callTx(busdContract, 'approve', [matchingMarketContract.address, busdFeeInWei], { from: signerAddress })
    }

    await callTx(
      matchingMarketContract,
      'offer(uint256,address,uint256,address,uint256)',
      [hPoolTokenAmountInWei, currentHPoolTokenContract.address, busdAmountInWei, busdContract.address, 0],
      { from: signerAddress },
    )

    // update tradingPairs
    setTimeout(() => {
      const { currentHPoolToken } = getState().tradingPair
      dispatch(setBuyOrders(currentHPoolToken))
      dispatch(setSellOrders(currentHPoolToken))
      dispatch(setBalance(busdContract, 'busdBalance'))
      dispatch(setBalance(currentHPoolTokenContract, 'currentHPoolTokenBalance'))
      dispatch(setSellLoading())
      dispatch(clearInputs())
    }, 3000)
  } catch (error) {
    // CATCH ERROR
    console.log(error)
    dispatch(setSellLoading())
    dispatch(clearInputs())
    dispatch(createNotification('error', error.message, 4000))
  }
}

export const getChartStatsAction = (poolAddress) => async (dispatch) => {
  try {
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_REQUEST })
    const statsRes = await fetchChartStats(poolAddress)
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_SUCCESS, payload: statsRes.stats })
  } catch (error) {
    dispatch({ type: orderbookActionTypes.SET_CHART_STATS_FAILURE, payload: error })
  }
}

export const setAvailableOpenOrders =
  (orders = []) =>
  async (dispatch) => {
    dispatch({ type: orderbookActionTypes.SET_AVAILABLE_OPEN_ORDERS, payload: orders })
  }
