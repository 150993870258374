import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: calc(100% - 4rem);
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

// Table Styles
export const Table = styled.table`
  width: 100%;
  height: 100%;
  text-align: left;
  border-collapse: collapse;
  display: table;
`
export const Thead = styled.thead`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.5;
  tr {
    display: grid;
    grid-template-columns: 1fr 5fr;
    margin-bottom: 1rem;
  }
`
export const Tbody = styled.tbody`
  color: white;
  font-size: 1.2rem;
  height: 18rem;
  display: block;
  overflow-y: auto;
  tr {
    display: grid;
    grid-template-columns: 1fr 5fr;
    align-items: center;
  }
`
export const Tr = styled.tr``
export const Th = styled.th`
  font-weight: 00;
`

export const LastTransaction = styled.div`
  background-color: #0D0D0D;
  padding: 0.7rem 1rem;
  text-align: center;
  margin: 1rem 0;
  border-radius: 0.5rem;
  color: #ffffff;

  span {
    font-weight: 700;
    color: #16c79a;
  }

  @media only screen and (max-width: 600px) {
    height: fit-content;
    margin: 2rem 0;
  }
`

export const VolumeHigh = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.green};
  `}
`

export const VolumeLow = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.red} !important;
  `}
`
