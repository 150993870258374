import { usePromise } from 'hooks/usePromise'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { newGetContractAddress } from 'utils/contractReader'
import { eventSetup } from 'utils/events'
import LogKill from './LogKill'
import LogMake from './LogMake'
import LogTake from './LogTake'

interface IOrdersEventsListenersContext {
  pendingOrders: any[]
  executedOrders: any[]
  account: string
  matchingMarketContractAddress: string
}

export const WEB_SOCKET_DISCONNECTED = 'WEB_SOCKET_DISCONNECTED'

export const OrdersEventsListenersContext = createContext<IOrdersEventsListenersContext>({
  pendingOrders: [],
  executedOrders: [],
  account: '',
  matchingMarketContractAddress: '',
})

export default function OrdersEventsListeners() {
  const [renderChildren, setRenderChildren] = useState(true)
  const {
    pendingOrders = [],
    executedOrders = [],
    account,
  } = useSelector((state: any) => ({
    pendingOrders: state.orderbook.pendingOrders,
    executedOrders: state.orderbook.executedOrders,
    account: state.wallet.account,
  }))
  const [, matchingMarketContractAddress] = usePromise(() => newGetContractAddress('MatchingMarket'), { initReq: true })

  const rerenderChildren = () => {
    // inorder to reset all listeners inside in some cases (websocket is disconnected)
    setRenderChildren(false)
    setTimeout(() => {
      console.log('restarting all event listeners...')
      setRenderChildren(true)
    }, 100)
  }

  useEffect(() => {
    eventSetup.on(WEB_SOCKET_DISCONNECTED, rerenderChildren)
  }, [])

  return (
    <OrdersEventsListenersContext.Provider
      value={{ pendingOrders, account, matchingMarketContractAddress, executedOrders }}
    >
      {renderChildren && (
        <>
          <LogKill />
          <LogMake />
          <LogTake />
        </>
      )}
    </OrdersEventsListenersContext.Provider>
  )
}
