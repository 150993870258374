import styled, { css } from 'styled-components'
import { CheckboxPropx } from './index'

export const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Style the checkmark/indicator */
  span:after {
    margin-left: 0.7rem;
    margin-top: 0.2rem;
    width: 0.4rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
const wrapperModifier = {
  red: () => css`
    background-color: #ff5353;
  `,
  green: () => css`
    background-color: #16c79a;
  `,
}

export const Input = styled.input<Partial<CheckboxPropx>>`
  ${({ backgroundColor }) => css`
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    /* When the checkbox is checked*/
    :checked ~ span {
      background-color: #ff5353;
      ${backgroundColor && wrapperModifier[backgroundColor]}
      border: none;
    }

    /* Show the checkmark when checked */
    :checked ~ span:after {
      display: block;
    }
  `}
`

export const Span = styled.span`
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  margin-right: 1rem;
  border-radius: 0.4rem;
  border: 1px solid #fff;

  /* Checkmark/indicator (hidden when not checked) */
  :after {
    content: '';
    display: none;
  }
`
export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`
