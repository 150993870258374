import React from 'react'

const StakeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
    <path
      d="M1 9.5L7 1V6H11.5L5.75 14.5V9.5H1Z"
      stroke="#928F9C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default StakeIcon
