import { WEB_SOCKET_DISCONNECTED } from 'views/dashboard/components/blocks/Transactions/EventsListeners'
import Web3 from 'web3'
import { offerMethodAbi } from './constants/vpool'
import {
  createContract,
  createWssContract,
  getContractAddress,
  getValueByMultiProps,
  newContractReader,
  newGetContractAddress,
} from './contractReader'
import { eventPoolingGetter } from './dlabService'
import config from '../config/config.json'
import { parseInputs } from './txProcessing'
import { isMatched } from './validator'
import { assetAmountInEth } from './assetsService'
import { getAccount } from './walletService'
import { wait } from './utilsService'
import { WeiToEth } from './web3Service'
import { eventSetup } from './events'

export const formatItemsUpdateEvents = async (passedEvents) => {
  let result = {}
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const events = passedEvents ?? (await eventPoolingGetter(matchingMarketContractAddress, 'LogItemUpdate')) ?? []
  events.forEach((evt) => {
    const txHash = getValueByMultiProps(evt, ['transactionHash', 'transaction_hash'])
    const { id } = evt ?? {}
    result = { ...result, [txHash]: id }
  })

  return result
}

export const getIfActiveOrder = (orderId, matchingMarketContractAddress) => {
  if (!Number.isFinite(+orderId)) return true
  return newContractReader({
    contractName: 'MatchingMarket',
    contractAddress: matchingMarketContractAddress,
    functionName: 'isActive',
    params: [orderId],
  })
}
window.getIfActiveOrder = getIfActiveOrder
export const getTradeOrderFee = async (poolAddress, matchingMarketContractAddress) => {
  const result = await newContractReader({
    contractName: 'MatchingMarket',
    functionName: 'poolToPlatformFee',
    contractAddress: matchingMarketContractAddress,
    params: [poolAddress],
  })
  let { totalTradingFeesInStableCoin } = result ?? {}
  totalTradingFeesInStableCoin = assetAmountInEth(totalTradingFeesInStableCoin)
  return totalTradingFeesInStableCoin
}

export const isNotBusd = (tokenAddress) => !isMatched(tokenAddress, config.busd)
export const isBusd = (tokenAddress) => isMatched(tokenAddress, config.busd)

const findOrderType = (payedToken, from, account) => {
  let isBuy = false
  isBuy = !isNotBusd(payedToken) // if the payed token is busd (initially it's a buy order)
  // const userIsTheOrderOrigin = isMatched(from, account)
  // if (!userIsTheOrderOrigin && account) isBuy = !isBuy // if user was not the order origin order type is inverted (buy for you is sell for me), no account means no need to inverse
  return isBuy ? 'buy' : 'sell'
}

const getPrice = (orderType, payAmt, buyAmt) => {
  const isBuy = orderType === 'buy'
  const buyCase = payAmt / buyAmt
  const sellCase = buyAmt / payAmt
  return isBuy ? buyCase : sellCase
}

const getTotalPaid = (prasedInputs) => {
  let result
  const { pay_gem: payedToken, pay_amt: payAmt, buy_amt: buyAmt } = prasedInputs
  const isPayedInBusd = !isNotBusd(payedToken)
  if (isPayedInBusd) result = payAmt
  else result = buyAmt
  return assetAmountInEth(result)
}

export const getDetailedOrderInfo = async (event, matchingMarketContractAddress, account) => {
  try {
    const { timestamp, returnValues = {} } = event ?? {}
    const txHash = getValueByMultiProps(event, ['transactionHash', 'transaction_hash'])

    const { input: txInput, from, to } = (await window._web3.eth.getTransaction(txHash)) ?? {}
    const prasedInputs = await parseInputs(offerMethodAbi, txInput)

    const { buy_gem: boughtToken, pay_gem: payedToken } = prasedInputs?.pay_gem ? prasedInputs : returnValues
    const { pay_amt: payAmt = returnValues?.take_amt, buy_amt: buyAmt = returnValues?.give_amt } = prasedInputs?.pay_gem
      ? prasedInputs
      : returnValues

    const orderType = findOrderType(payedToken, from, account)
    const isPaidWithBUSD = isBusd(payedToken) // if it's not then it's a sell
    const _side = isPaidWithBUSD ? 'buy' : 'sell'
    const _price = +WeiToEth(isPaidWithBUSD ? payAmt : buyAmt || 0)
    const [targetToken] = [boughtToken, payedToken].filter(isNotBusd)

    const fee = await getTradeOrderFee(targetToken, matchingMarketContractAddress)
    const targetAmt = isMatched(targetToken, boughtToken) ? buyAmt : payAmt

    const amount = WeiToEth(targetAmt)
    const price = getPrice(orderType, payAmt, buyAmt)

    // const totalPaid = getTotalPaid(prasedInputs)
    const date = new Date(+timestamp * 1000)
    const ts = timestamp * 1000
    return {
      fee: +fee,
      ...prasedInputs,
      ...returnValues,
      side: _side,
      from,
      to,
      price: +_price,
      hpool_token_price: _price,
      total: amount,
      ts,
      date,
      coinAddress: targetToken,
    }
  } catch (error) {
    console.log({ getDetailedOrderInfoErr: error })
    return {}
  }
}

export const getExecutedOrders = async (account) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const events = (await eventPoolingGetter(matchingMarketContractAddress, 'LogTake')) ?? []
  const formattedEvents = await Promise.all(
    events.map(async (event) => {
      const detailedInfo = await getDetailedOrderInfo(event, matchingMarketContractAddress, account)
      return { ...event, ...detailedInfo }
    }),
  )
  return formattedEvents
}

export const isBuyOrder = (account, tx, payedTokenAddress) => {
  const { from, to } = tx ?? {}
  const payedInBusd = isMatched(payedTokenAddress, config.busd)
  const isBuy = (from === account && payedInBusd) || (to === account && !payedInBusd) // either payedInBusd or receieved in token
  return isBuy
}

export const setupWeb3EventListener = async (eventName, onData = (data) => {}, onError = (err) => {}) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const matchingMarketContract = await createWssContract('MatchingMarket', matchingMarketContractAddress)

  const subscribtionObj = matchingMarketContract.events?.[eventName]?.()
    .on('connected', (subscriptionId) => {
      console.log({ eventConnected: `${eventName} is connected, subscriptionId: ${subscriptionId}` })
    })
    .on('data', (event) => {
      onData(event)
    })
    .on('error', async (error, receipt) => {
      // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
      console.log({ setupWeb3EventListenerErr: error })
      await wait(400)
      eventSetup.trigger(WEB_SOCKET_DISCONNECTED)
      onError(error, receipt)
    })
  return subscribtionObj
}

export const getPastEvents = async (eventName) => {
  try {
    const latestBlockNumber = await window._web3.eth.getBlockNumber()
    await wait(1500) // wait is added cuz getPastEvents has a limit (e.g 3 time per second)
    const fromBlock = latestBlockNumber - 300 // 2000 should cover last 15min blocks
    const web3Instance = new Web3(config.rpcUrl)

    const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
    const matchingMarketContract = await createContract('MatchingMarket', matchingMarketContractAddress, web3Instance)

    const last15MinEvents =
      matchingMarketContract.getPastEvents(eventName, { fromBlock, toBlock: latestBlockNumber }) || []

    return last15MinEvents
  } catch (error) {
    console.log({ getPastEventsError: error })
    return []
  }
}

export const appendOrderId = async (evt) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')
  const txHash = getValueByMultiProps(evt, ['transaction_hash', 'transactionHash'])
  const { input: txInput } = await window._web3.eth.getTransaction(txHash)
  const prasedInputs = await parseInputs(offerMethodAbi, txInput)
  const id = window.ordersStatusSrc?.[txHash]
  const isActiveOrder = await getIfActiveOrder(id, matchingMarketContractAddress)
  return { ...evt, id, isActiveOrder, prasedInputs }
}

// DAPI response model is the standard => u gotta map web3 getPastEvents like DAPI's one
// 1- get from dapi
// 2- get from getPastEvents
// 3- map getPastEvents response to suit DAPI's model
// merge DAPI's and getPastEvents
// when logKill Evt happens just remove the targeted id from orderbook.pendingOrder

export const mapToFitDapiModel = (evt) => {
  const { returnValues } = evt
  if (!returnValues) return evt
  return { ...evt, ...returnValues, returnValues }
}

export const getTxHash = (tx) => getValueByMultiProps(tx, ['transactionHash', 'transaction_hash'])

const isDuplicateTx = (txToTest = '', foundHashes = []) =>
  foundHashes.find((duplicateHash) => isMatched(getTxHash(txToTest), duplicateHash))

export const removeDuplicatesTxs = (txs = [], foundHashes) => txs.filter((tx) => !isDuplicateTx(tx, foundHashes))

export const getBothDapiAndWeb3Events = async (evtName) => {
  const matchingMarketContractAddress = await newGetContractAddress('MatchingMarket')

  let web3LibEvts = (await getPastEvents(evtName)) || [] // should be small array compared to dapiEvts
  web3LibEvts = web3LibEvts.map(mapToFitDapiModel)
  console.log({ web3LibEvts })
  const orderHashes = web3LibEvts.map(getTxHash)

  let dapiEvts = (await eventPoolingGetter(matchingMarketContractAddress, evtName)) || []
  dapiEvts = removeDuplicatesTxs(dapiEvts, orderHashes)
  return [...dapiEvts, ...web3LibEvts]
}

export const getOrderIdFromTxHash = (ev) => {
  const txHash = getValueByMultiProps(ev, ['transactionHash', 'transaction_hash'])
  return { ...ev, id: window.ordersStatusSrc?.[txHash] }
}

// TODO: make sure both records from DAPI and web3 have the same object model (remap when necessary)
export const getPrevOpenOrders = async () => {
  try {
    const allPrevLogUpdates = await getBothDapiAndWeb3Events('LogItemUpdate') // to get orders ids
    const ordersStatusSrc = (await formatItemsUpdateEvents(allPrevLogUpdates)) || {}
    window.ordersStatusSrc = ordersStatusSrc

    const allPrevLogMakes = (await getBothDapiAndWeb3Events('LogMake')) || []
    // allPrevLogMakes = allPrevLogMakes.map(getOrderIdFromTxHash)

    const allOrders = await Promise.all(allPrevLogMakes.map(appendOrderId))

    const inActiveOrders = allOrders.filter((order) => order.isActiveOrder)
    return inActiveOrders
  } catch (error) {
    console.log({ getOpenOrdersError: error })
  }
}

export const getPrevCompletedOrders = async () => {
  try {
    const allPrevLogTakes = (await getBothDapiAndWeb3Events('LogTake')) || []
    // allPrevLogMakes = allPrevLogMakes.map(getOrderIdFromTxHash)
    const allOrders = await Promise.all(allPrevLogTakes.map(appendOrderId))
    return allOrders
  } catch (error) {
    console.log({ getOpenOrdersError: error })
  }
}

export const cancelOrderRequest = async (orderId, account) =>
  newContractReader({
    contractName: 'MatchingMarket',
    functionName: 'cancel',
    params: [orderId],
    sendParams: {
      from: account,
    },
  })

window.cancelOrderRequest = cancelOrderRequest
window.getExecutedOrders = getExecutedOrders
