import Dec from 'decimal.js'
import { ASSETS, DEFAULT_ASSET } from './constants/assets'

export const assetAmountInEth = (amount) => {
  const decimals = 18
  return Dec(amount?.toString() || 0)
    .div(10 ** decimals)
    .toString()
}

const getAssetAllowanceForAddress = async (owner, spender, contract) => {
  const data = await contract.allowance(owner, spender).call()

  return assetAmountInEth(data)
}

export const isAddressApprovedOnAsset = async (account, addressToCheck, contract, amount) => {
  const allowance = await getAssetAllowanceForAddress(account, addressToCheck, contract)
  return Dec(allowance).gte(amount)
}

export const getAssetInfo = (assetSymbol) =>
  ASSETS.find((t) => t.symbol.toLowerCase() === assetSymbol?.toLowerCase()) ||
  console.trace(`Asset "${assetSymbol}" not found `) || { ...DEFAULT_ASSET }

export const assetAmountInWei = (amount = 0, assetSymbol = 'ETH') => {
  const { decimals } = getAssetInfo(assetSymbol)

  return Dec(amount?.toString() || 0)
    .mul(10 ** decimals)
    .floor()
    .toFixed()
    .toString()
}

window.assetAmountInWei = assetAmountInWei
