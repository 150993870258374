import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { WalletConnector } from '@dcentralab/web3-wallet-connector'
import { Header as DUFHeader } from '@dcentralab/unified-frontend'
import LogoIcon from './LogoIcon'
import CreatePoolButton from './CreatePoolButton/CreatePoolButton'
import CustomLink from './CustomLink/CustomLink'
import HordButtons from './HordButtons/HordButtons'
import {
  onWalletConnectSuccessAction,
  onWalletDisconnectAction,
  onWalletConnectRequestAction,
  onWalletConnectErrorAction,
  switchNetwork,
} from '../../../store/actions/walletActions'
import { connectToContracts } from '../../../store/actions/contractActions'
import config from '../../../config/config.json'
import { LS_ACCOUNT } from '../../../utils/constants/general'
import TokenBalance from '../TokenBalance/TokenBalance'
import './Header.scss'
import Icon from '../Icon'
import LogoIconWide from './LogoIconWide'
import ChampionsIcon from './SidebarIcons/Champions'
import DexIcon from './SidebarIcons/Dex'
import HomeIcon from './SidebarIcons/Home'
import PortfolioIcon from './SidebarIcons/Portfolio'
import StakeIcon from './SidebarIcons/Stake'
import HordLogoWide from './HordLogoWide'

interface IHeaderProps {
  championData?: object
  isChampionVerified?: boolean
  account?: string
  MatchingMarket?: object
}
interface IHeaderActionProps {
  onWalletConnectSuccess: () => void
  onWalletDisconnect: () => void
  onWalletConnectRequest: () => void
  onWalletConnectError: () => void
  connectToContracts: () => void
  switchNetwork: (payload: any) => void
}

const Header = ({
  onWalletConnectSuccess,
  onWalletDisconnect,
  onWalletConnectRequest,
  onWalletConnectError,
  connectToContracts,
  account,
  switchNetwork,
}: IHeaderProps & IHeaderActionProps) => {
  const intl = useIntl()
  const [checked, setChecked] = useState(false)

  const onNetworkSelect = useCallback(() => {
    switchNetwork({ rpcUrl: config.rpcUrl, networkId: config.network })
  }, [])

  const sidebarLinks = useMemo(
    () => [
      {
        id: 'viking-dao',
        text: 'Viking Dao',
        icon: <HomeIcon />,
        href: `${config.vdaoApp}`,
      },
      {
        id: 'champion-pool',
        text: 'Champions Pool',
        icon: <ChampionsIcon />,
        href: `${config.vdaoApp}/champion`,
      },
      {
        separator: true,
      },
      {
        id: 'dex',
        text: 'DEX',
        icon: <DexIcon />,
        to: '/',
      },
      {
        id: 'stake-hord',
        text: 'Stake HORD',
        icon: <StakeIcon />,
        href: `${config.vdaoApp}/token`,
      },
      {
        separator: true,
      },
      {
        id: 'portfolio',
        text: 'My Portfolio',
        icon: <PortfolioIcon />,
        href: `${config.vdaoApp}/portfolio`,
      },
    ],
    [],
  )

  const networkSelectorProps = useMemo(
    () => ({
      networks: [{ networkId: config.network }],
      networkId: config.network,
      account,
      onNetworkSelect,
    }),
    [account, onNetworkSelect],
  )

  const logo = useMemo(
    () => ({
      href: config.hordApp,
      img: <LogoIconWide />,
      imgWide: <LogoIcon />,
    }),
    [],
  )

  const emptyLogo = {
    img: '',
  }

  // re added account until i figure out why we need it
  useEffect(() => {
    connectToContracts()
  }, [connectToContracts, account])

  useEffect(() => {
    const accountType = localStorage.getItem(LS_ACCOUNT)
    if (accountType) {
      switchNetwork({ rpcUrl: config.rpcUrl, networkId: config.network })
    }
  }, [])

  const closeMenu = () => {
    if (checked) setChecked(false)
  }

  return (
    <DUFHeader
      logo={logo}
      projectLogo={emptyLogo}
      sidebarLinks={sidebarLinks}
      networkSelectorProps={networkSelectorProps}
      topContent={
        <>
          <div className="sidebar-mobile-logo">
            <HordLogoWide className="scale-on-hover" />
          </div>
        </>
      }
      walletComponent={
        <WalletConnector
          intl={intl}
          networkId={config.network}
          rpcUrl={config.rpcUrl}
          onClick={closeMenu}
          onConnectRequest={onWalletConnectRequest}
          onConnect={onWalletConnectSuccess}
          onConnectError={onWalletConnectError}
          onDisconnect={onWalletDisconnect}
          autoConnectInPageProvider
          showBalance
        />
      }
    >
      <TokenBalance />
    </DUFHeader>
  )
}

const mapStateToProps = (state) => ({
  championData: state.champion?.championData,
  isChampionVerified: state.champion?.championData?.is_verified_champion === 'VERIFIED',
  account: state.wallet.account,
  MatchingMarket: state.contracts.MatchingMarket,
})

const mapDispatchToProps = {
  onWalletConnectSuccess: onWalletConnectSuccessAction,
  onWalletDisconnect: onWalletDisconnectAction,
  onWalletConnectRequest: onWalletConnectRequestAction,
  onWalletConnectError: onWalletConnectErrorAction,
  connectToContracts,
  switchNetwork,
}

// @ts-ignore
export default connect<IHeaderProps, IHeaderActionProps, {}>(mapStateToProps, mapDispatchToProps)(Header)
