import { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import Dinero from 'dinero.js'
import { setTradingPairsInformation } from 'store/actions/tradingPairActions'
import { findMinMax } from 'utils/array'
import { arraySort } from 'utils/arraySort'
import * as S from './styles'
import NavbarPair from '../../../../../components/general/NavbarPair'
import NavbarItem from '../../../../../components/general/NavbarItem'

type BlockProps = {
  volume: number
  high: number
  low: number
  blockPrice: string
}

export type IPairs = { name: string; image: string; address: string; symbol: string; id?: string }

type Props = {
  blockValues: BlockProps
  lastTradePrice: number
  lastTradePriceType: 'AskLimit' | 'BidLimit'
  account: any
  chartStats: any
  tokenSymbol: string
  hPoolTokensList: IPairs[]
  HPoolFactory: object
  HPoolManager: object
  setTradingPairsInformation: (HPoolFactory: object, HPoolManager: object) => void
  loading: boolean
}
const Navbar = ({
  blockValues,
  lastTradePrice,
  lastTradePriceType,
  account,
  chartStats,
  tokenSymbol,
  hPoolTokensList,
  HPoolFactory,
  HPoolManager,
  setTradingPairsInformation,
  loading,
}: Props) => {
  useEffect(() => {
    if (HPoolFactory) {
      setTradingPairsInformation(HPoolFactory, HPoolManager)
    }
  }, [HPoolFactory, HPoolManager])

  const { executedOrders, poolTokenContract } = useSelector((state: any) => ({
    executedOrders: state.orderbook?.executedOrders,
    poolTokenContract: state.tradingPair?.currentHPoolToken?.contract_address,
  }))

  const { dailyHigh, dailyLow, price24hr, volume24hr, latestTradePrice } = useMemo(() => {
    const currentTokenExcecutedOrders =
      executedOrders?.filter((order) => order?.coinAddress === poolTokenContract) || []
    const sortedOrdersByTimestamp = arraySort(currentTokenExcecutedOrders, 'ts', { isAsc: false, isString: false }) // 1st item is latest order last item is oldest

    const oneDayInMs = 24 * 60 * 60 * 1000
    const yesterday = new Date().getTime() - oneDayInMs
    const latestTradePrice = sortedOrdersByTimestamp?.[0]?.price || 0
    const last24HrStats = sortedOrdersByTimestamp?.filter((stat) => stat?.ts >= yesterday)
    const volume24hr = last24HrStats?.reduce((acc, curr) => acc + curr?.price * +curr?.total, 0) || 0
    const dailyHigh = last24HrStats?.length ? findMinMax(last24HrStats, 'price', true) : 0
    const dailyLow = last24HrStats?.length ? findMinMax(last24HrStats, 'price') : 0
    const priceDifference24Hr = last24HrStats?.[0]?.price - last24HrStats?.[last24HrStats?.length - 1]?.price
    const pricePercentageDifference = priceDifference24Hr * 100 || 0

    return {
      dailyHigh,
      dailyLow,
      price24hr: pricePercentageDifference,
      volume24hr,
      latestTradePrice,
    }
  }, [executedOrders, poolTokenContract])

  return (
    <S.Wrapper className="NavbarWrapper">
      <S.WrapperInfo>
        <S.ContainerPair>
          <NavbarPair coin="BUSD" pairs={hPoolTokensList || []} />
        </S.ContainerPair>
        <S.ContainerInfo>
          <NavbarItem
            loading={loading}
            label="Last trade"
            info={Dinero({ amount: Math.round(+latestTradePrice * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
          />
          <NavbarItem
            loading={loading}
            label="24h change"
            info={`${+price24hr?.toFixed(2)}%` || 0}
            infoStyle={{ color: price24hr < 0 ? '#FF5353' : '#16c79a' }}
          />
          <NavbarItem
            loading={loading}
            label="24h volume"
            info={Dinero({ amount: Math.round(+volume24hr * 100) || 0 })
              .toFormat('$0,0.00')
              .toString()
              .slice(0, 6)}
          />
          <NavbarItem
            loading={loading}
            label="24h high"
            info={Dinero({ amount: Math.round(+dailyHigh * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
            infoStyle={{ color: '#16C79A' }}
          />
          <NavbarItem
            loading={loading}
            label="24h low"
            info={Dinero({ amount: Math.round(+dailyLow * 100) || 0 }).toFormat('$0,0.00')}
            type={lastTradePriceType}
            infoStyle={{ color: '#FF5353' }}
          />
        </S.ContainerInfo>
      </S.WrapperInfo>
    </S.Wrapper>
  )
}

const mapStateToProps = (state) => ({
  hPoolTokensList: state.tradingPair.hPoolTokensList,
  HPoolFactory: state.contracts.HPoolFactory,
  HPoolManager: state.contracts.HordHpoolManager,
  tokenSymbol: state.tradingPair?.currentHPoolToken?.symbol,
  chartStats: state.orderbook?.chartStats?.stats,
  loading: state.orderbook?.chartStats?.loading,
})

export default connect(mapStateToProps, { setTradingPairsInformation })(Navbar)
