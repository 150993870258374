import config from '../config/config.json'

export const eventPoolingGetter = (contractAddress, eventName) => {
  const url = `${config.dlabBaseUrl}/event_polling/contracts_events_info?collection_name=Hord-FE&contract_addresses=${contractAddress}&user_web3_addresses=${contractAddress}&event_names=${eventName}`
  const headers = {
    Authorization: `Hord-FE,6ATdUwvLPpFPWCq5qTsVHWvB2Xs`, // TODO: refactor this to env var
  }
  return fetch(url, { headers }).then((res) => res.json())
}

window.eventPoolingGetter = eventPoolingGetter
