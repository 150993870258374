import React, { useEffect, useMemo, useState } from 'react'
import { clearInputs, handleInputChange } from 'store/actions/inputActions'
import { connect } from 'react-redux'
import { makeBuyOrder, makeSellOrder } from 'store/actions/orderbookActions'
import { Flex } from 'styles/layouts'
import Button from '../Button'
import Icon from '../Icon'
import Input from '../Input'
import Range from '../Range'
import * as S from './styles'

import { MarketOrderActionProps } from './MarketOrderActionProps'

const FEE_PERCENT = 0.025

const MarketOrderAction = ({
  type = 'Buy',
  amount: passedAmt,
  price: passedPrice,
  focusedOrderType,
  setOpenOrder,
  buyPrice,
  sellPrice,
  buyAmount,
  sellAmount,
  buyTokenPrice,
  sellTokenPrice,
  handleInputChange,
  account,
  buyLoading,
  sellLoading,
  blockchainApi,
  orderType,
  hpoolStats,
  // setActiveIndex,
  busdBalance,
  currentHPoolTokenBalance,
  currentHPoolTokenName,
  currentHPoolTokenSymbol,
  // clearInputs,
  makeSellOrder,
  makeBuyOrder,
}: MarketOrderActionProps) => {
  const [slider, setSlider] = useState({ values: [0] })
  const [available, setAvailable] = useState(0)
  const [busdFee, setBusdFee] = useState(0)

  const isBuy = type === 'Buy'

  const avail = isBuy ? busdBalance : currentHPoolTokenBalance
  const max = isBuy ? busdBalance : currentHPoolTokenBalance || 0

  passedAmt = passedAmt >= max ? max : passedAmt

  const [price, setPrice] = useState(0)
  const [amount, setAmount] = useState(0)

  const _busdFee = FEE_PERCENT * price * amount

  useEffect(() => {
    setAmount(passedAmt)
  }, [passedAmt])

  useEffect(() => {
    setPrice(passedPrice || 0)
  }, [passedPrice])

  useEffect(() => {
    if (type === 'Buy') {
      // if (!amount) return
      // const percentage = slider.values?.[0] / 100
      // const parsedAmt = +amount
      // const prevAmt = +parsedAmt.toFixed(2)
      // const newAmt = +(busdBalance * percentage).toFixed(2)
      // if (newAmt === prevAmt) return
      // setAmount(newAmt)
    }
  }, [slider, amount])

  useEffect(() => {
    const targetedBalance = type === 'Buy' ? busdBalance : currentHPoolTokenBalance

    const prevAmt = +(+amount)?.toFixed(2)
    const newSliderValue = prevAmt ? +(prevAmt / targetedBalance).toFixed(2) * 100 : 0
    setSlider({ values: [newSliderValue] })

    setBusdFee(slider.values?.[0] * amount * price * FEE_PERCENT)
  }, [amount])

  useEffect(() => {
    if (type.toLocaleLowerCase() === focusedOrderType) {
      setAmount(0)
      setPrice(0)
    } else {
      setPrice(passedPrice)
      setAmount(passedAmt)
    }
  }, [focusedOrderType])

  useEffect(() => {
    if (type === 'Buy') return setAvailable(busdBalance)
    setAvailable(currentHPoolTokenBalance)
  }, [currentHPoolTokenBalance, busdBalance, type, account])

  const { price24Hr } = useMemo(
    () => ({
      price24Hr: hpoolStats?.[hpoolStats?.length - 1]?.hpool_token_price,
    }),
    [hpoolStats],
  )

  useEffect(() => {
    handleInputChange('buyTokenPrice', price24Hr?.toFixed(4))
    handleInputChange('sellTokenPrice', price24Hr?.toFixed(4))
  }, [price24Hr])

  const startTransaction = async () => {
    if (type === 'Buy') {
      return makeBuyOrder(price, amount)
    }
    makeSellOrder(price, amount, _busdFee)
  }

  const getSliderValue = (inputAmount) => {
    if (!inputAmount) return 0
    const sliderVal = (inputAmount * 100) / available

    if (sliderVal > 100) return 100
    return sliderVal
  }

  const isValidNum = (input) => !isNaN(input) && input >= 0

  // Input logic will need refactoring/cleaning up
  const validatePrice = (inputPrice, _type) => {
    const sliderValue = getSliderValue(inputPrice)
    const calculatedBuyPrice = buyTokenPrice > 0 ? inputPrice / buyTokenPrice : inputPrice / price24Hr
    const calculatedSellPrice = sellTokenPrice > 0 ? inputPrice / sellTokenPrice : inputPrice / price24Hr

    if (isValidNum(inputPrice)) {
      if (_type === 'Buy') {
        handleInputChange('buyPrice', inputPrice)
        handleInputChange('buyAmount', calculatedBuyPrice.toFixed(6))
      } else {
        handleInputChange('sellPrice', inputPrice)
        handleInputChange('sellAmount', calculatedSellPrice.toFixed(6))
        setBusdFee(inputPrice * FEE_PERCENT)
      }
      setSlider({ values: [+sliderValue.toFixed(2)] })
    }
  }

  const validateAmount = (inputAmount, _type) => {
    const sliderValue = getSliderValue(inputAmount)
    const calculatedSellPrice = sellTokenPrice > 0 ? inputAmount * sellTokenPrice : inputAmount * price24Hr
    const calculatedBuyPrice = buyTokenPrice > 0 ? inputAmount * buyTokenPrice : inputAmount * price24Hr

    if (isValidNum(inputAmount)) {
      if (_type === 'Sell') {
        handleInputChange('sellAmount', inputAmount)
        handleInputChange('sellPrice', calculatedSellPrice.toFixed(2))
        setBusdFee(calculatedSellPrice * FEE_PERCENT)
      } else {
        handleInputChange('buyAmount', inputAmount)
        handleInputChange('buyPrice', calculatedBuyPrice.toFixed(2))
      }
    }
    setSlider({ values: [+sliderValue.toFixed(2)] })
  }

  const getAmountValue = (availableBalance, sliderValue = slider) =>
    (availableBalance * +sliderValue.values[0].toFixed(2)) / 100

  const setSliderValue = (sliderValue: { values: number[] }) => {
    const inputAmount = Number(getAmountValue(available, sliderValue).toFixed(2))
    const calculatedSellPrice = sellTokenPrice > 0 ? inputAmount * sellTokenPrice : inputAmount * price24Hr
    const calculatedBuyAmount = buyTokenPrice > 0 ? inputAmount / buyTokenPrice : buyPrice / price24Hr

    if (type === 'Buy') {
      setSlider(sliderValue)
      handleInputChange('buyPrice', inputAmount)
      return handleInputChange('buyAmount', calculatedBuyAmount.toFixed(4))
    }

    handleInputChange('sellAmount', inputAmount)
    handleInputChange('sellPrice', calculatedSellPrice.toFixed(4))
    setSlider(sliderValue)
    setBusdFee(amount * price * FEE_PERCENT)
  }

  const validateTokenPrice = (inputValue, _type) => {
    function setBuyTokenPrice() {
      const calculatedBuyAmount = inputValue > 0 ? buyPrice / inputValue : buyPrice / price24Hr

      if (buyPrice > 0) {
        handleInputChange('buyAmount', calculatedBuyAmount.toFixed(6))
      }
      handleInputChange('buyTokenPrice', inputValue)
    }

    function setSellTokenPrice() {
      const calculatedSellAmount = inputValue > 0 ? sellPrice / inputValue : sellPrice / price24Hr

      if (sellPrice > 0) {
        handleInputChange('sellAmount', calculatedSellAmount.toFixed(6))
      }
      handleInputChange('sellTokenPrice', inputValue)
    }

    if (isValidNum(inputValue)) {
      if (_type === 'Buy') {
        return setBuyTokenPrice()
      }
      setSellTokenPrice()
    }
  }

  const onSliderChange = (value) => {
    setSliderValue(value)

    const targetedBalance = type === 'Buy' ? busdBalance : currentHPoolTokenBalance
    const percentage = slider.values?.[0] / 100
    const parsedAmt = +amount
    const newAmt = +(targetedBalance * percentage).toFixed(2)

    const prevAmt = +(+amount)?.toFixed(2)
    const newSliderValue = prevAmt ? +(prevAmt / targetedBalance).toFixed(2) * 100 : 0

    if (newAmt === prevAmt) return
    setAmount(newAmt)
  }

  return (
    <S.WrapperOrder>
      <S.ContainerWallet>
        <Icon name={type === 'Buy' ? 'WalletGreen' : 'WalletRed'} />
        <S.WrapperBalance>
          <span style={{ opacity: '0.5' }}>Available Balance</span>
          <S.Span>
            {avail.toFixed(2)} {type === 'Buy' ? 'BUSD' : currentHPoolTokenName || 'HPool token'}
          </S.Span>
        </S.WrapperBalance>
      </S.ContainerWallet>
      <S.ContainerForm>
        <S.Form onSubmit={() => console.log('subitting')}>
          <S.InputContainer>
            <Input
              label="Token Price"
              placeholder="0"
              tooltipInfo="Current price of Hpool token in USD"
              containerStyle={{ marginRight: 0 }}
              value={price}
              type="text"
              inputInfo="&#36;"
              fullWidth
              setValue={(inputAmount) => setPrice(inputAmount)}
            />
            <S.InputWrapper>
              <Input
                label="Amount"
                tooltipInfo="Total amount of Hpool tokens"
                placeholder="0"
                value={amount}
                max={max}
                type="text"
                inputInfo={orderType === 'Market Order' && isBuy ? 'BUSD' : currentHPoolTokenSymbol}
                fullWidth
                setValue={(inputAmount) => setAmount(inputAmount)}
                infoStyle={{ color: isBuy ? '#16c79a' : '#ff5353', fontWeight: 600 }}
              />
              <Input
                disabled
                label="Total"
                tooltipInfo="Total price in BUSD"
                placeholder="0"
                value={price && amount ? price * amount : 0}
                type="text"
                inputInfo="BUSD"
                fullWidth
              />
            </S.InputWrapper>
            <S.WrapperActions>
              <Range disabled={!max} values={slider.values} setValues={onSliderChange} type={type} />
              {type === 'Sell' && (
                <>
                  <S.FeeLabelWrapper>
                    <S.FeeLabel>Fee</S.FeeLabel>
                  </S.FeeLabelWrapper>
                  <S.FeeWrapper>
                    <Flex alignItems="center">
                      <S.Fee>{_busdFee ? +_busdFee.toFixed(2) : 0}</S.Fee>
                      {_busdFee > 0 && <S.FeeCoin>BUSD</S.FeeCoin>}
                    </Flex>
                  </S.FeeWrapper>
                </>
              )}
            </S.WrapperActions>
          </S.InputContainer>

          <Button
            title={type}
            loading={type === 'Buy' ? buyLoading : sellLoading}
            loadingText="In Progress"
            className="mt-3"
            click={startTransaction}
            disabled={!account || !amount || !price}
          />
        </S.Form>
      </S.ContainerForm>
    </S.WrapperOrder>
  )
}

const mapStateToProps = (state) => ({
  amount: state.input.amount,
  price: state.input.price,
  focusedOrderType: state.input.focusedOrderType,
  buyPrice: state.input.buyPrice,
  sellPrice: state.input.sellPrice,
  buyAmount: state.input.buyAmount,
  sellAmount: state.input.sellAmount,
  buyTokenPrice: state.input.buyTokenPrice,
  sellTokenPrice: state.input.sellTokenPrice,
  account: state.wallet.account,
  currentHPoolTokenBalance: state.balances.currentHPoolTokenBalance,
  busdBalance: state.balances.busdBalance,
  currentHPoolTokenName: state.tradingPair.currentHPoolToken?.name,
  currentHPoolTokensymbol: state.tradingPair.currentHPoolToken?.symbol,
  currentHPoolTokenAddress: state.tradingPair.currentHPoolToken?.address,
  buyLoading: state.ui.buyLoading,
  sellLoading: state.ui.sellLoading,
  hpoolStats: state.orderbook?.chartStats?.stats,
})

export default connect(mapStateToProps, {
  handleInputChange,
  makeBuyOrder,
  makeSellOrder,
  // clearInputs,
})(MarketOrderAction)
