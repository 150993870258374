export const SET_BUY_ORDERS = 'SET_BUY_ORDERS'
export const SET_SELL_ORDERS = 'SET_SELL_ORDERS'
export const SET_TRADE_HISTORY = 'SET_TRADE_HISTORY'
export const BUY = 'BUY'
export const SELL = 'SELL'
export const SET_TRADING_PAIR = 'SET_TRADING_PAIR'

export const SET_CHART_STATS_REQUEST = 'SET_CHART_STATS_REQUEST'
export const SET_CHART_STATS_SUCCESS = 'SET_CHART_STATS_SUCCESS'
export const SET_CHART_STATS_FAILURE = 'SET_CHART_STATS_FAILURE'

export const SET_AVAILABLE_OPEN_ORDERS = 'SET_AVAILABLE_OPEN_ORDERS'

export const SET_PENDING_ORDERS = 'SET_PENDING_ORDERS'
export const SET_EXECUTED_ORDERS = 'SET_EXECUTED_ORDERS'

export const SET_EXECUTED_ORDERS_SOURCE = 'SET_EXECUTED_ORDERS_SOURCE'
