import Icon from '../Icon'
import * as S from './styles'

export type SearchInputProps = {
  placeholder: string
  type: string
  value?: any
  setValue?: any
}
const SearchInput = ({ placeholder, type, value, setValue }: SearchInputProps) => (
  <S.Wrapper>
    <S.IconWrapper>
      <Icon style={{ width: '100%' }} name="Search" />
    </S.IconWrapper>
    <S.Input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => setValue && setValue(e.target.value)}
    />
  </S.Wrapper>
)

export default SearchInput
