import { usePromise } from 'hooks/usePromise'
import React, { useContext, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { SET_EXECUTED_ORDERS_SOURCE, SET_PENDING_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import { newGetContractAddress } from 'utils/contractReader'
import { getDetailedOrderInfo, mapToFitDapiModel, setupWeb3EventListener } from 'utils/tradeOrdersService'
import { isMatched } from 'utils/validator'
import { OrdersEventsListenersContext } from '.'

export default function LogMake() {
  const dispatch = useDispatch()
  const { pendingOrders, account, matchingMarketContractAddress, executedOrders } =
    useContext(OrdersEventsListenersContext)

  const onlyUsersTxs = (tx) => isMatched(tx.maker, account) || isMatched(tx.taker, account)
  const createOrdersSrc = (ordersArr = []) =>
    ordersArr.reduce((prev, elem) => ({ ...prev, [+elem.id]: (prev?.[+elem.id] || 0) + +elem.total }), {})

  const usersExecutedOrder = useMemo(() => executedOrders.filter(onlyUsersTxs), [executedOrders.length, account])
  const usersPendingOrder = useMemo(() => pendingOrders.filter(onlyUsersTxs), [pendingOrders.length, account])
  const completedOrdersSrc = useMemo(() => createOrdersSrc(executedOrders), [usersExecutedOrder.length, account])

  useEffect(() => {
    dispatch({ type: SET_EXECUTED_ORDERS_SOURCE, payload: completedOrdersSrc })
  }, [completedOrdersSrc, usersPendingOrder])

  useEffect(() => {
    const subscribtionObj: any = setupWeb3EventListener('LogMake', async (evt: any) => {
      console.log('LogMake', evt)
      const mappedToFitDapiModel = mapToFitDapiModel(evt)
      console.log('LogMake 1', mappedToFitDapiModel)
      console.log('LogMake 2', matchingMarketContractAddress)

      const orderInfo = await getDetailedOrderInfo(mappedToFitDapiModel, matchingMarketContractAddress, account)
      console.log('LogMake 3')

      console.log({ orderInfo, mappedToFitDapiModel, matchingMarketContractAddress, account })

      dispatch({ type: SET_PENDING_ORDERS, payload: [...pendingOrders, orderInfo] })
    })

    return () => {
      subscribtionObj.then((subscribtion) =>
        subscribtion.unsubscribe((err, success) => {
          console.log('removing subscribtion ' + subscribtion.id)
        }),
      )
    }
  }, [pendingOrders.length, matchingMarketContractAddress, account])

  return <></>
}
