import styled from 'styled-components'

// Order Box
export const WrapperOrder = styled.div`
  background: #080808;
  padding: 2rem 2rem 3rem 2rem;
  width: 100%;

  @media only screen and (max-width: 1650px) {
    border-radius: 15px;
  }
  &:first-of-type {
    border-top-right-radius: 15px;
    padding-bottom: 0;

    @media only screen and (max-width: 1650px) {
      padding-bottom: 3rem;
    }
  }

  &:last-of-type {
    border-bottom-right-radius: 15px;
  }

  @media only screen and (max-width: 1200px) {
    box-shadow: unset;
    border-radius: 15px;
  }
`

export const ContainerWallet = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`
export const WrapperBalance = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`
export const Span = styled.span`
  font-size: 2rem;
  font-weight: Medium;
  color: #fff;
`
export const ContainerForm = styled.div`
  color: #dcf307;
  height: 100%;
`

export const Form = styled.form`
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 960px) {
    height: unset;
  }
`

export const InputContainer = styled.div``

export const InputWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1200px) {
    flex: 1;
    justify-content: space-between;
  }

  & div {
    flex: 1;
  }

  @media only screen and (max-width: 350px) {
    flex-direction: column;

    & div {
      margin-right: 0;
    }
  }
`

export const WrapperActions = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-bottom: 1rem;
`

export const FeeLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FeeLabel = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: #969799;
  margin-bottom: 0.5rem;
`

export const FeeWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Fee = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`

export const FeeCoin = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.5rem;
  opacity: 0.3;
`
