import styled, { css } from 'styled-components'

import { NavbarItemPops } from '.'

export const Wrapper = styled.div`
  flex: 1;

  & div {
    @media only screen and (max-width: 650px) {
      margin-bottom: 0.5rem;
    }

    @media only screen and (max-width: 450px) {
      transform: translate(calc(50% - 6px));
    }
  }
`
export const Label = styled.span`
  font-size: 1.2rem;
  color: #fff;
  opacity: 0.5;
  font-weight: 600;
`
export const Info = styled.p<Partial<NavbarItemPops>>`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.reg};
    font-weight: 700;
    margin: 0;
  `}
`

export const VolumeHigh = styled.div`
  p {
    ${({ theme }) => css`
      color: ${theme.colors.green};
    `}
  }
`

export const VolumeLow = styled.div`
  p {
    ${({ theme }) => css`
      color: ${theme.colors.primary};
    `}
  }
`
