import React from 'react'
import { IOrderBookData } from '../../../views/dashboard/components/blocks/Graph/IGraph'

import * as S from './styles'
import OrderBookTableBody from '../OrderBookTableBody'

export type OrderBookTableProps = {
  orderBookAsks: IOrderBookData[]
  orderBookBids: IOrderBookData[]
  latestTransaction: string
  latestTransactionType: string
}

const OrderBookTable = ({
  latestTransaction,
  latestTransactionType,
  orderBookAsks,
  orderBookBids,
}: OrderBookTableProps) => (
  <S.Wrapper>
    <S.Table>
      <S.Thead>
        <S.Tr>
          <S.Th>Price</S.Th>
          <S.Th>Amount</S.Th>
        </S.Tr>
      </S.Thead>
      <OrderBookTableBody data={orderBookBids} isScrollBottom />
    </S.Table>

    <S.LastTransaction>
      <p>
        Latest transaction&nbsp;
        {latestTransactionType ? (
          latestTransactionType === 'sell' ? (
            <S.VolumeLow>{latestTransaction}</S.VolumeLow>
          ) : (
            <S.VolumeHigh>{latestTransaction}</S.VolumeHigh>
          )
        ) : (
          <span>{latestTransaction}</span>
        )}
      </p>
    </S.LastTransaction>

    <S.Table>
      <S.Thead>
        <S.Tr>
          <S.Th>Price</S.Th>
          <S.Th>Amount</S.Th>
        </S.Tr>
      </S.Thead>
      <OrderBookTableBody data={orderBookAsks} isScrollBottom={false} />
    </S.Table>
  </S.Wrapper>
)

export default OrderBookTable
