import styled from 'styled-components'

export const Tr = styled.tr`
  padding: 1.2rem;
`
export const Td = styled.td`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`
export const ContainerFlex = styled.div`
  display: flex;
  align-items: center;
`

export const Tag = styled.span`
  display: block;
  margin-right: 1rem;
  display: none;
  opacity: 0.5;

  @media only screen and (max-width: 600px) {
    display: block;
  }
`

export const Status = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;
  }
`
export const Button = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ff5353;
  cursor: pointer;
  margin-left: 1rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`
