import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBalance } from 'store/actions/balanceActions'
import { SET_EXECUTED_ORDERS, SET_PENDING_ORDERS } from 'store/actionTypes/orderbookActionTypes'
import { getDetailedOrderInfo, mapToFitDapiModel, setupWeb3EventListener } from 'utils/tradeOrdersService'
import { isMatched } from 'utils/validator'
import { getAccount } from 'utils/walletService'
import { OrdersEventsListenersContext } from '.'
import { invertOrderType } from '..'

export default function LogTake() {
  const dispatch = useDispatch()
  const { poolTokenContract, busdContract } = useSelector((state: any) => ({
    poolTokenContract: state.contracts?.currentHPoolTokenContract,
    busdContract: state.contracts?.BUSD,
  }))
  const { executedOrders, pendingOrders, account, matchingMarketContractAddress } =
    useContext(OrdersEventsListenersContext)

  const onlyUsersTxs = (evt) => isMatched(evt.maker, account) || isMatched(evt.taker, account)

  useEffect(() => {
    const subscribtionObj: any = setupWeb3EventListener('LogTake', async (evt: any) => {
      const mappedToFitDapiModel = mapToFitDapiModel(evt)
      const orderInfo = (await getDetailedOrderInfo(mappedToFitDapiModel, matchingMarketContractAddress, account)) || {}
      const { side: prevSide, total: takingAmt, taker, maker } = orderInfo
      const amTheMaker = isMatched(account, maker)

      const correctOrderType = !amTheMaker ? prevSide : invertOrderType(prevSide) // invert only when ur the maker

      // TODO: do not delete the whole order just check the new order amount and substract it from the matching order
      const deletedOrderId = parseInt(mappedToFitDapiModel.id as string, 16)

      const newPendingOrders = pendingOrders.map((order) => {
        if (+order.id === +deletedOrderId) return { ...order, total: order.total - +takingAmt }
        return order
      })

      const newExecutedOrder = {
        ...orderInfo,
        side: correctOrderType,
      }

      if (onlyUsersTxs(orderInfo)) {
        // maker already paid all his stuff ()
        // update balances
        dispatch(setBalance(busdContract, 'busdBalance'))
        dispatch(setBalance(poolTokenContract, 'currentHPoolTokenBalance'))
      }

      dispatch({ type: SET_EXECUTED_ORDERS, payload: [...executedOrders, newExecutedOrder] })
      // dispatch({ type: SET_PENDING_ORDERS, payload: newPendingOrders })
    })

    return () => {
      subscribtionObj.then((subscribtion) =>
        subscribtion.unsubscribe((err, success) => {
          console.log('removing subscribtion ' + subscribtion.id)
        }),
      )
    }
  }, [executedOrders.length, pendingOrders.length, matchingMarketContractAddress, account])

  return <></>
}
