import styled, { css } from 'styled-components'

import { DropdownProps } from '.'

export const Wrapper = styled.div`
  width: fit-content;
  position: relative;
  z-index: 10;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.span`
  margin: 0.5rem;
`

export const WrapperImage = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;

    :hover {
      opacity: 0.8;
    }
  `}
`

export const Image = styled.img<Partial<DropdownProps>>`
  ${({ active }) => css`
    transform: ${active ? 'rotate(180deg)' : 'rotate(0deg)'};
  `}
  width: 0.8rem;
`

// Dropdown Content
export const WrapperContent = styled.div`
  position: absolute;
  background-color: #000;
  color: white;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1rem;
  max-height: 30rem;
  overflow-y: scroll;
  width: 30rem;
  top: 35px;
  left: 0;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 550px) {
    left: unset;
    right: 0;
  }

  @media only screen and (max-width: 375px) {
    width: 24rem;
  }
`
export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
`
