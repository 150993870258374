import { contractReader } from '../../../../../utils/contractReader'
import { createContract, getContractsMeta } from '../../../../../utils/contractsRegistryService'
import configJson from '../../../../../config/config.json'

export const loadVPools = async (HPoolFactory) => {
  if (HPoolFactory) {
    const startIndex = 0
    const endIndex = await contractReader(HPoolFactory, 'getEndIndexForDeployedVPools')
    const vPools = await contractReader(HPoolFactory, 'getDeployedVPools', [startIndex, endIndex])

    const { VPool } = await getContractsMeta()
    if (vPools.length > 0) {
      const vpoolInfo = await Promise.all(
        vPools.map(async (address) => {
          const contract = createContract(address, VPool.abi)
          const info = await contractReader(contract.methods, 'vPool')
          const bePoolId = await contractReader(contract.methods, 'bePoolId')
          const name = await contractReader(contract.methods, 'name')
          const symbol = await contractReader(contract.methods, 'symbol')

          return { vpoolId: info.vPoolId, address, symbol, name, bePoolId }
        }),
      )

      const config = {
        method: 'GET',
        headers: { Authorization: 'Bearer GUEST' },
      }

      const vpoolsInfo = await Promise.all(
        vpoolInfo.map(async (currentVPool) => {
          const url = new URL(`${configJson.apiPath}/hpool/${currentVPool.bePoolId}`)
          const { hpool } = await fetch(url, config).then((res) => res.json())

          const name = `${hpool?.name} - ${currentVPool?.name}`

          return { ...hpool, ...currentVPool, nftId: hpool?.hpool_nft_id, name }
        }),
      )

      const activePools = vpoolsInfo.filter((pool) => pool.status === 'ACTIVE')
      const createParms = (hpool) =>
        new URLSearchParams({
          nft_id: hpool.nftId,
          chain_id: window.networkId,
        }).toString()

      const vPoolsWithNftImage = await Promise.all(
        // TODO: refactor this with hPoolsWithNftImage
        activePools.map(async (vpool) => {
          const url = new URL(`${configJson.apiPath}/champion/nft?${createParms(vpool)}`)
          const { nft } = await fetch(url, config).then((res) => res.json())
          return { ...vpool, image: nft?.image_s3 }
        }),
      )

      return vPoolsWithNftImage
    }
  }
}
