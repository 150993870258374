import { InputHTMLAttributes } from 'react'
import Tooltip from 'components/Tooltip'
import * as S from './styles'

export interface InputProps extends InputHTMLAttributes<any> {
  label?: string
  icon?: 'None' | 'ArrowVerticalBottom' | 'ArrowVerticalTop'
  placeholder?: string
  type?: 'text' | 'email' | 'password' | 'url' | 'number'
  value?: any
  max?: number
  inputInfo?: string
  fullWidth?: boolean
  setValue?: any
  containerStyle?: object
  infoStyle?: object
  tooltipInfo?: string
}

const Input = ({
  label = 'Label',
  placeholder = 'Text..',
  type = 'text',
  value = '',
  max,
  icon = 'None',
  inputInfo,
  fullWidth = false,
  setValue,
  containerStyle,
  infoStyle,
  tooltipInfo,
  ...rest
}: InputProps) => {
  const handleChange = ({ target: { value: newValue } }) => {
    if (+newValue > max || max === 0) return
    setValue && setValue(newValue)
  }

  return (
    <S.Wrapper style={containerStyle}>
      <S.ContainerInput>
        <Tooltip show text={tooltipInfo}>
          <S.Label>{label}</S.Label>
        </Tooltip>
        <S.Box inputInfo={inputInfo} fullWidth={fullWidth}>
          <S.Input placeholder={placeholder} type={type} value={value || ''} onChange={handleChange} {...rest} />
          {inputInfo && <S.Span style={infoStyle}>{inputInfo}</S.Span>}
        </S.Box>
      </S.ContainerInput>
    </S.Wrapper>
  )
}

export default Input
