import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreState } from 'store/configureStore'
import { getTokenBalance as getTokenBalanceReq } from 'services/tokensService'
import { formatNumber } from 'services/format'
import { getContractAddress } from 'utils/contractReader'
import { updateBalance } from 'store/actions/walletActions'
import { usePromise } from './usePromise'

interface IUseTokenBalance {
  tokenAddress?: string
  contractName?: string
}

export const useTokenBalance = ({ tokenAddress, contractName }: IUseTokenBalance) => {
  const dispatch = useDispatch()
  const { account, balances } = useSelector((state: IStoreState) => ({
    account: state.wallet.account,
    balances: state.wallet.balances,
  }))

  const [getTokenBalance, tokenBalance = 0, fetchingBalance] = usePromise(getTokenBalanceReq)
  const [getContractAddressReq, tokenContractAddress, gettingTokenAddress] = usePromise(() =>
    getContractAddress(contractName),
  )

  const formattedBalance = formatNumber(tokenBalance || 0)
  const finalTokenAddress: string = tokenAddress || tokenContractAddress || ''

  const hasStoredBalance = balances?.[finalTokenAddress?.toLowerCase?.()] || 0

  const onBalanceFetched = (balance: number, address: string) => {
    if (!Number.isFinite(balance)) return
    dispatch(updateBalance(address.toLowerCase(), balance))
  }

  useEffect(() => {
    if (!finalTokenAddress || !account || gettingTokenAddress) return
    getTokenBalance(account, finalTokenAddress).then((balance: number) => onBalanceFetched(balance, finalTokenAddress))
  }, [account, finalTokenAddress])

  useEffect(() => {
    if (contractName) getContractAddressReq(contractName)
  }, [contractName])

  const toolkit = {
    account,
    getTokenBalance,
    onBalanceFetched,
  }

  return [hasStoredBalance || tokenBalance, fetchingBalance, formattedBalance, toolkit]
}
