import styled from 'styled-components'

export const Wrapper = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`
export const Input = styled.input`
  width: 100%;
`
