import { contractReader } from '../../utils/contractReader'
import { WeiToEth } from '../../utils/web3Service'
import * as balanceActionTypes from '../actionTypes/balanceActionTypes'

export const setBalance = (contract, tokenName) => async (dispatch, getState) => {
  let balance = 0
  const signerAddress = getState().wallet.account

  if (signerAddress) {
    const weiAmountOfTokens = await contractReader(contract, 'balanceOf', [signerAddress])
    if (!weiAmountOfTokens?.error) {
      balance = Number(WeiToEth(weiAmountOfTokens))
    }
  }
  dispatch({
    type: balanceActionTypes.SET_BALANCE,
    payload: { balance, tokenName },
  })
}

export const unsetBalances = () => ({
  type: balanceActionTypes.REMOVE_BALANCES,
})
